/**
 * Created by Robert on 6/14/19.
 */
import React, {Component} from 'react';
import * as styles from '../../../../styles/assessment.scss';
import PropTypes from 'prop-types';

class ConcreteRelationalSecondaryPartialView extends Component {

    render() {
        return (
            <div>
                <div>
                    <h4 className={styles.learningOutcome}>Concrete Relational (CR): {this.props.score}</h4>
                    <p className={styles.fontSmall1350}>
                        A secondary Concrete Relational score indicates that you have the capacity to engage with
                        clearly defined learning content (whether that content consists facts, details, examples,
                        or well-defined concepts or theories) and the ability to grasp the relational context
                        that co-defines or illuminates the primary learning content.  In fact, in this mode you
                        can even trace pathways through that context in order to understand different
                        perspectives or possible solutions.
                        <br/><br/>
                        In your Concrete Relational capacity you have the ability to see into complex, confusing,
                        and difficult topics and situations, and a talent for brainstorming and creative problem
                        solving.  In this mode you are just as comfortable uncovering root causes, underlying
                        motives, and potential conflicts as you are identifying possible solutions (whether
                        conventional or unconventional).  In short, this mode of thinking allows you to see the
                        central object from many different angles, work through complex problems, and develop
                        practical solutions.
                        <br/><br/>
                        Your secondary learning style can be especially important to you when your environment or
                        circumstances ask things of you that outstrip the strengths and abilities of your primary
                        learning style.  You are most likely to tap into this secondary learning style if you are
                        asked to take on a new challenge, and especially one that requires you to think
                        critically and come up with a range of innovative answers.  You might also tap into this
                        learning style if you take on a leadership role that requires you to see things from
                        multiple perspectives, assimilate them, and chart a course toward preset goals.  In those
                        situations, your secondary Concrete Relational learning style serves as a back-up mode
                        that allows you to take in lots of information while staying focused on the issues at
                        hand and identify creative possibilities to fit your needs. Game on!
                    </p>
                </div>
            </div>
        );
    }
}

ConcreteRelationalSecondaryPartialView.propTypes = {
    score: PropTypes.number
};

export default ConcreteRelationalSecondaryPartialView;