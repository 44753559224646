/**
 * Created by Robert on 6/15/19.
 */
/**
 * Created by Robert on 6/15/19.
 */
import React from 'react';
import { Link } from 'react-router-dom';
import * as styles from '../styles/main.scss';

const NotFoundView = () => (
    <div>
        <section className={`${styles.section} ${styles.fullHeightStrict} ${styles.vCentered}`}>
            <article className={`${styles.tile} ${styles.isChild} ${styles.notification} ${styles.whiteTile} ${styles.welcomeTile}`}
            style={{height: '100%'}}>
                <p className={styles.title}>404 - Not Found</p>
                <div className={styles.content}>
                    <Link className={`${styles.button} ${styles.isSeafoam}`} to="/">Back to Start</Link>
                </div>
            </article>
        </section>
    </div>
);

export default NotFoundView;

