/**
 * Created by Robert on 6/25/19.
 */
import React, {Component} from 'react';
import {Bar} from '@nivo/bar';
import categoriesConfig from '../../../../../config/assessment/values/questions';
import PropTypes from 'prop-types';
import * as styles from '../../../../../styles/assessment.scss';

class ValuesAssessmentResultsAggregate extends Component {
    constructor(props) {
        super(props);
        const categories = this.props.categories;
        this.categoriesNames = [];
        this.data = [];
        for (const [category, value] of Object.entries(categories)) {
            const name = categoriesConfig.categories[category].name;
            this.categoriesNames.push(name);
            this.data.push({name: name, value: value});
        }
    }

    render() {
        const { config } = this.props;
        return (
            <div className={'results-nivo-container'}>
                <Bar
                    groupMode='grouped'
                    width={1000}
                    height={750}
                    margin={{top: 10, right: 150, bottom: 250, left: 150}}
                    data={this.data}
                    indexBy={'name'}
                    keys={['value']}
                    colors={config['scoring']['aggregate']['legend']['colors']}
                    borderWidth={2}
                    borderColor={{from: 'color'}}
                    animate={true}
                    motionStiffness={90}
                    layout={'vertical'}
                    maxValue="15"
                    motionDamping={15}
                    isInteractive={true}
                    axisBottom={{
                        tickRotation: -45
                    }}
                    theme={{
                        axis: {
                            fontSize: '16px',
                            ticks: {
                                text: {
                                    fontSize: 16
                                }
                            },
                            legend: {
                                text: {
                                    fontSize: 16
                                }
                            },
                        }
                    }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                />
            </div>
        );
    }
}

ValuesAssessmentResultsAggregate.propTypes = {
    config: PropTypes.object,
    categories: PropTypes.object,
};

export default ValuesAssessmentResultsAggregate;