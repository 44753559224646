/**
 * Created by Robert on 6/23/19.
 */
/**
 * Created by Robert on 6/14/19.
 */
import React, { Component } from 'react';
import * as styles from '../../../styles/assessment.scss';
import '../../../styles/animate.css';
import LazyImage from '../../../components/lazyimage.js';
import titleBgFull from '../../../images/learning-assessment/title-bg.jpg';
import titleBgPreload from '../../../images/learning-assessment/title-bg-blur.jpg';
import config from '../../../config/assessment/learning/questions.json';

class LearningAssessmentQuestionsView extends Component {
    constructor(props) {
        super(props);
        this.partOneContainer = [];
        this.partTwoContainer = [];

        this.partOneTitle = renderTitle('part 1');
        this.partOneQuestions = renderP1Questions();

        this.partTwoTitle = renderTitle('part 2');
    }

    render() {
        if (this.partOneContainer.length === 0) {
            this.partOneContainer.push(this.partOneTitle);
            this.partOneContainer.push(this.partOneQuestions);
        }

        if (this.partTwoContainer.length === 0) {
            this.partTwoContainer.push(this.partTwoTitle);
            this.partTwoContainer.push(renderParagraphQuestions());
        }

        return (
            <div>
                <div id="part-one-container">
                    {this.partOneContainer}
                </div>
                <div id="part-two-container">
                    {this.partTwoContainer}
                </div>
            </div>
        );
    }

    componentDidMount() {
        const radioButtons = document.getElementsByClassName(styles.formRadio);
        const onRadioButtonClick = (event) => {
            const radioButton = event.target;
            if (radioButton.dataset.level == 4 && radioButton.dataset.part == 1) {
                for (let otherQuestion = 1; otherQuestion < 5; otherQuestion++) {
                    if (otherQuestion == radioButton.dataset.question) {
                        continue;
                    }

                    const otherId = `answers-container-part-1-group-${radioButton.dataset.group}-question-${otherQuestion}-level-` + 4;
                    const otherRadio = document.getElementById(otherId);

                    if (otherRadio.checked) {
                        otherRadio.checked = false;
                        const level4Id = `answers-container-part-1-group-${otherRadio.dataset.group}-question-${otherRadio.dataset.question}-level-` + 3;
                        const level4Button = document.getElementById(level4Id);
                        level4Button.click();
                        otherRadio.classList.remove(styles.overwriteQuestion);
                        setTimeout(() => {
                            otherRadio.classList.add(styles.overwriteQuestion);
                        }, 1);
                        break;
                    }
                }
            }

            const container = document.getElementById(
                `answers-container-part-${radioButton.dataset.part}-group-${radioButton.dataset.group}-question-${radioButton.dataset.question}`);
            container.innerHTML = '<h4><i>' + config['answer levels'][radioButton.dataset.level] + '</i></h4>';
        };

        for (let i = 0; i < radioButtons.length; i++) {
            const radioButton = radioButtons[i];
            radioButton.addEventListener('click', onRadioButtonClick);
        }
    }
}

function renderTitle(type) {
    return (
        <section key='learning-title' className={`${styles.section} ${styles.vCentered} ${styles.widePadding} ${styles.halfHeight}`}>
            <LazyImage
                className={styles.backgroundContainer}
                bg={true}
                srcPreload={titleBgPreload}
                srcLoaded={titleBgFull}
                id={'learning-title-bg-' + type}
            />
            <div className={`${styles.columnCount} ${styles.isMultiline} ${styles.lazyBackgroundContent}`}>
                <div className={`${styles.column} ${styles.isFull}`}>
                    <h1>{config[type].heading.title}</h1>
                </div>
                <div className={`${styles.column} ${styles.isFull}`}>
                    <h2>{config[type].heading.subtitle}</h2>
                </div>
            </div>
        </section>
    );
}

function renderP1Questions() {
    const questions = config['part 1'].questions;

    const elementArray = [];

    for (let i = 0; i < Object.keys(questions).length; i++) {
        const questionSet = questions[i].split(',');
        const questionGroup = [];

        for (let j = 0; j < questionSet.length; j++) {
            const hrSection = j < questionSet.length - 1 ? (<hr/>) : '';
            let helper;

            if (j === questionSet.length - 1) {
                helper = '';
            } else {
                helper = styles.questionsMultilineHelper;
            }
            const question1Content = !j && (<h4 className={styles.groupNumber}>Group {i + 1}.</h4>)
            questionGroup.push(
                <div key={'p1q-' + i + '-' + j} className={styles.questionItem}>
                    <div id={'p1-question-' + (j + 1)}
                         className={`${styles.columnCount} ${styles.isMultiline} ${styles.questionsMultilineColumn}`}>
                        <div className={`${styles.column} ${styles.is3} ${!question1Content ? styles.isHiddenMobile : styles.shrinkUpperPaddingMobile}`}>
                            {question1Content}
                        </div>
                        <div className={`${styles.column} ${styles.is2} ${styles.shrinkUpperPaddingMobile}`}>
                            <h4 className={`${styles.breakAnywhere} ${styles.questionTitle}`}>{questionSet[j]}</h4>
                        </div>
                        <div
                            className={`${styles.column} ${styles.is3} ${styles.answersContainer} ${styles.vCentered} ${styles.shrinkUpperPaddingMobile}`}>
                            {getAnswerOptions(1, i + 1, j + 1)}
                        </div>
                        <div className={`${styles.column} ${styles.is4} ${styles.answersLevelText} ${styles.isHiddenMobile}`}>
                            <div id={'answers-container-part-1-group-' + (i + 1) + '-question-' + (j + 1)}
                                 data-group={'question-group-' + (i + 1)}>
                            </div>
                        </div>
                    </div>
                    {/* Shown when on mobile */}
                    <div className={`${styles.columnCount} ${styles.isMultiline} ${helper} ${styles.isShownOnlyTouch}`}>
                        <div className={styles.optionsSpanner}>
                            <p>
                                <i>{config['answer levels'][0]}</i>
                            </p>
                            <p>
                                <i>{config['answer levels'][4]}</i>
                            </p>
                        </div>
                    </div>
                    {/* Shown when on any other platform */}
                    <div
                        className={`${styles.columnCount} ${styles.isMultiline} ${helper} ${styles.isShownNeverTouch}`}>
                        <div className={`${styles.column} ${styles.is4}`}>
                        </div>
                        <div className={`${styles.column} ${styles.is3}`} style={{textAlign: 'center'}}>
                            <p>
                                <i>{config['answer levels'][0]}</i>
                            </p>
                        </div>
                        <div className={`${styles.column} ${styles.is2} ${styles.level5Text}`} style={{textAlign: 'left'}}>
                            <p>
                                <i>{config['answer levels'][4]}</i>
                            </p>
                        </div>
                        <div className={`${styles.column} ${styles.is3}`}>
                        </div>
                    </div>
                    {hrSection}
                </div>);
        }

        const colorClass = (i % 2 !== 0 ? styles.backgroundLearningDarker : styles.backgroundLearning);
        const element = (
            <section key={'p1gs-' + (i + 1)}
                     className={`${styles.section} ${styles.questionSection} ${styles.vCentered} ${styles.widePaddingMedium} ${colorClass}`}>
                <div id={'p1-question-group-' + (i + 1)} className={styles.column}>
                    {questionGroup}
                </div>
            </section>);

        elementArray.push(element);
    }

    return elementArray;
}

function renderParagraphQuestions() {
    const elementArray = [];

    for (let i = 0; i < config['part 2'].questions.length; i++) {
        const colorClass = (i % 2 !== 0 ? styles.backgroundLearningDarker : styles.backgroundLearning);
        const question = config['part 2'].questions[i];
        elementArray.push(
            <section key={'pgq-' + (i + 1)} id={'paragraph-question-' + (i + 1)}
                     className={`${styles.section} ${styles.widePaddingMedium} ${styles.widePaddingCondensedComputer} ${styles.paragraphQuestionSection} ${colorClass}`}>
                <div className={`${styles.columnCount}`}>
                    <div className={`${styles.column} ${styles.is10}`}>
                        <div className={`${styles.columnCount} ${styles.isMultiline} ${styles.smallPaddingMedium}`}>
                            <div className={`${styles.column} ${styles.isFull}`}>
                                <h4 className={`${styles.noPaddingSmall}`}>{question}</h4>
                            </div>
                        </div>
                        <div
                            className={`${styles.columnCount} 
                    ${styles.isMultiline} 
                    ${styles.questionsMultilineHelper} 
                    ${styles.isHiddenTouch}`}>
                            <div className={`${styles.column} ${styles.is2}`}>
                            </div>
                            <div className={`${styles.column} ${styles.is3} ${styles.vCentered}`}>
                                <p>
                                    <i>{config['answer levels'][0]}</i>
                                </p>
                            </div>
                            <div className={`${styles.column} ${styles.is2}`}>
                            </div>
                            <div className={`${styles.column} ${styles.is3} ${styles.vCentered}`}>
                                <p>
                                    <i>{config['answer levels'][4]}</i>
                                </p>
                            </div>
                            <div className={`${styles.column} ${styles.is2}`}>
                            </div>
                        </div>
                        <div className={`${styles.columnCount} ${styles.isMultiline}`}>
                            <div className={`${styles.column} ${styles.isFull} ${styles.answersContainer} ${styles.vCentered}`}>
                                {getAnswerOptions(2, 1, i + 1)}
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.column} ${styles.is2} ${styles.answersWrapper}`}>
                        <div
                            id={'answers-container-part-2-group-1-question-' + (i + 1)}
                            data-group={'question-group-1'}>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    return elementArray;
}

function getAnswerOptions(part, group, question) {
    const inputs = [];

    for (let i = 0; i < 5; i++) {
        const identifier = 'part-' + part + '-group-' + group + '-question-' + question;
        const identifierFull = 'answers-container-' + identifier + '-level-' + i;
        const numberClass = styles['formRadio' + (i + 1)];
        inputs.push(
            <input
                key={'a' + i + 'q' + question + 'g' + group}
                className={`${styles.formRadio} ${numberClass}`}
                type='radio'
                name={identifier}
                data-level={i}
                data-part={part}
                data-group={group}
                data-question={question}
                id={identifierFull}
            />);
    }

    return inputs;
}

export default LearningAssessmentQuestionsView;