import React, { Component } from 'react';
import * as styles from '../../../../styles/assessment.scss';

class CrtModeratelyPreparedPartialView extends Component {

    render() {
        return (
            <React.Fragment>
                <h4 className={styles.crtModeratelyPrepared}>80-99 Moderately Prepared</h4>
                <p>
                    An aggregate score between 80 and 99 indicates that while you have acquired some or most of the skills necessary
                    to be successful at the college- or university-level of education, you have key areas that remain underdeveloped.
                    As an entering student, you are likely to depend heavily on your existing base of skills, and you may struggle
                    when your skills abilities are stretched to their limits. When required to use your weaker or underdeveloped
                    skills, you may feel overwhelmed, unable to perform at your best, or incapable of completing your requirements.
                    These skills weaknesses will become more of a hinderance over time as more is required of you as a student. To
                    put this in perspective, ongoing skills deficiencies can result in lower academic performance; over 60% of
                    students with a GPA of less than 3.0 after their first year will not graduate within 6 years of entering college.
                    For many families, this can be a costly and disheartening experience.
                    <br/><br/>
                    Fortunately, these consequences can be easily avoided with the proper supports. Our recommendation is to
                    proactively address weak skills areas and re-solidify your overarching foundation of academic, personal, and pre-
                    professional skills, and we’re here to help!Our Master Courses and Success Coaching options provide a
                    comprehensive approach to address any and all areas of concern. Learn more <a href="https://www.highpoint.education/collections">HERE</a>.
                    <br/><br/>
                    If you have concerns about your score, about our products, or about the timeframe wherein you need to see results,
                    contact us directly at info@highpoint.education to learn more about how we can meet your needs.
                </p>
            </React.Fragment>
        );
    }
}

export default CrtModeratelyPreparedPartialView;