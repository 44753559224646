import React, {Component} from 'react';
import * as styles from '../../../../../styles/assessment.scss';
import config from '../../../../../config/assessment/values/questions.json';
import PropTypes from 'prop-types';

class ValuesDefinitionChart extends Component {
    constructor(props) {
        super(props);

        this.value = this.props.value;
        this.score = this.props.score;
        this.definition = this.props.definition;
        this.supportingValues = this.populateValues(this.props.supporting);
        this.associatedValues = this.populateValues(this.props.associated);
        this.conflictingValues = this.populateValues(this.props.conflicting);
        this.backgroundImage = this.props.backgroundImage;
    }

    populateValues(values) {
        const array = [];
        let key = 0;
        for (const value of values) {
            array.push(<li key={key++}>
                {getNamedValue(value)}
            </li>);
        }

        return array;
    }

    render() {
        const backgroundStyle = {
            background: `url('${this.backgroundImage}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            marginBottom: '10px'
        };
        return (
            <React.Fragment>
                <div className={`${styles.columnCount} ${styles.isMultiline} ${styles.valuesContainer}`}>
                    <div className={`${styles.column} ${styles.isFull} ${styles.definitionBox}`} style={backgroundStyle}>
                        <h3><span className={styles.valuesScore}>{this.score}</span>&nbsp;{getNamedValue(this.value)}</h3>
                        <p>{this.definition}</p>
                    </div>
                    <div className={`${styles.column} ${styles.isOneThird} ${styles.supportingColumn}`}>
                        <h4>Supporting</h4>
                        <ul>{this.supportingValues}</ul>
                    </div>
                    <div className={`${styles.column} ${styles.isOneThird} ${styles.associatedColumn}`}>
                        <h4>Associated</h4>
                        <ul>{this.associatedValues}</ul>
                    </div>
                    <div className={`${styles.column} ${styles.isOneThird} ${styles.conflictingColumn}`}>
                        <h4>Conflicting</h4>
                        <ul>{this.conflictingValues}</ul>
                    </div>
                </div>
                <div/>
            </React.Fragment>
        );
    }
}

function getNamedValue(valueId) {
    return config.categories[valueId].name;
}

ValuesDefinitionChart.propTypes = {
    value: PropTypes.string,
    score: PropTypes.number,
    definition: PropTypes.string,
    supporting: PropTypes.array,
    associated: PropTypes.array,
    conflicting: PropTypes.array,
    backgroundImage: PropTypes.string
};
export default ValuesDefinitionChart;