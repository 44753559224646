export const FEATURE_FLAGS = {
    VALUES_ASSESSMENT: {
        enabledIn: [
            'development',
            'staging'
        ],
        default: false,
        parameter: null
    },
    CRT: {
        enabledIn: [
            'development',
            'staging'
        ],
        default: false,
        parameter: null
    },
    AUTOFILL_ANSWERS: {
        enabledIn: [
            'development',
            'staging'
        ],
        default: false,
        parameter: 'autofill'
    }
};

// By default, treat a parameter value as a boolean.
const defaultParameterHandler = (parameterValue) => (parameterValue.toLowerCase() == 'true' || parameterValue === '1');

// Check if the flag is enabled
/*
* flag: the flag object
* search: this.props.location.search from the component calling this function
* handler: the handler that is called when the url parameter is present.
* */
export const isFlagEnabled = (flag, search = null, handler = defaultParameterHandler) => {
    if (flag.enabledIn.includes(process.env.NODE_ENV)) {
        if(flag.parameter && search) {
            const query = new URLSearchParams(search);
            const value = query.get(flag.parameter);
            // Handle the value that is receive;
            return handler(value);
        } else if(flag.parameter && !search) {
            return flag.default;
        }
        return true;
    }
    return flag.default;
};