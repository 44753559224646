/**
 * Created by Robert on 6/25/19.
 */
import React from 'react';
import {Radar} from '@nivo/radar';
import config from '../../../../config/assessment/learning/results.json';
import AssessmentResultsChartView from '../../components/asessment-results-chart-view';
import * as styles from '../../../../styles/assessment.scss';

class LearningAssessmentResultsRadarView extends AssessmentResultsChartView {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={'results-nivo-container'}>
                <Radar
                    width={1000}
                    height={1000}
                    margin={{top: 50, right: 190, bottom: 30, left: 190}}
                    data={this.data}
                    indexBy="Type"
                    keys={['Perceived', 'Tested']}
                    colors={config['scoring']['radar']['legend']['colors']}
                    maxValue="75"
                    curve="linearClosed"
                    borderWidth={2}
                    borderColor={{from: 'color'}}
                    gridLevels={5}
                    gridShape="circular"
                    gridLabelOffset={36}
                    enableDots={true}
                    dotSize={10}
                    dotColor={{theme: 'background'}}
                    dotBorderWidth={2}
                    dotBorderColor={{from: 'color'}}
                    enableDotLabel={true}
                    dotLabel="value"
                    dotLabelYOffset={-12}
                    fillOpacity={0.25}
                    blendMode="multiply"
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    isInteractive={true}
                    axisBottom={{
                        tickRotation: (this.props.height > 600) ? 0 : -45
                    }}
                    theme={{
                        axis: {
                            fontSize: '16px',
                            ticks: {
                                text: {
                                    fontSize: 16
                                }
                            },
                            legend: {
                                text: {
                                    fontSize: 16
                                }
                            },
                        }
                    }}
                    legends={[
                        {
                            anchor: 'top-left',
                            direction: 'column',
                            translateX: -50,
                            translateY: -40,
                            itemWidth: 80,
                            itemHeight: 20,
                            itemTextColor: '#999',
                            symbolSize: 12,
                            symbolShape: 'circle',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemTextColor: '#000'
                                    }
                                }
                            ]
                        }
                    ]}
                />
            </div>
        );
    }
}

export default LearningAssessmentResultsRadarView;
/**
 * Created by Robert on 6/29/19.
 */
