/**
 * Created by Robert on 6/14/19.
 */
import React, {Component} from 'react';
import * as styles from '../../../../styles/assessment.scss';
import PropTypes from 'prop-types';

class ConcreteSequentialSecondaryPartialView extends Component {

    render() {
        return (
            <div>
                <div>
                    <h4 className={styles.learningOutcome}>Concrete Sequential (CS): {this.props.score}</h4>
                    <p className={styles.fontSmall1350}>
                        A secondary Concrete Sequential score indicates that you have the capacity to engage with
                        well-established, clearly defined learning content (e.g., facts, details, objects,
                        examples, case studies, etc.) and the ability to order this content in a clear,
                        systematic, and logical way.  You can also make crisp, clear categorical distinctions as
                        a means of breaking learning content down into digestible, understandable packets that
                        can be ordered and easily remembered.
                        <br/><br/>
                        In your Concrete Sequential capacity, you have the ability to organize your materials and
                        workspaces, time and tasks with relative ease.  In this mode, you also have the ability
                        to shift from learning and analysis to the execution of tasks without too much difficulty
                        because you more readily close the gap between thinking and doing.  In this mode you are
                        not prone to procrastination, and you don’t need a “big picture” view of things to get to
                        work.
                        <br/><br/>
                        Your secondary learning style can be especially important to you when your environment or
                        circumstances ask things of you that outstrip the strengths and abilities of your primary
                        learning style.  You are most likely to tap into this secondary learning style if you are
                        asked to remember facts and details, make distinctions, or operate in a highly focused
                        and methodical manner as you work on certain tasks or projects.  In those situations,
                        your secondary Concrete Sequential learning style serves as a back-up mode that allows
                        you to be competent, motivated, self-reliant, hard-working, and consistent.  Nice Work!
                    </p>
                </div>
            </div>
        );
    }
}

ConcreteSequentialSecondaryPartialView.propTypes = {
    score: PropTypes.number
};

export default ConcreteSequentialSecondaryPartialView;