import axios from 'axios';
import InputValidationUtil from '../../common/utility/input-validation.util';
import * as styles from '../../highpo-client/styles/assessment.scss';

export class FormHandler {
    static postResults(jsonData) {
        if(document.getElementById('terms-and-conditions').checked) {
            // Oops, looks like our bot friend fell for our trick!
            jsonData.type = "bogus";
            return;
        }
        axios.post('/api/assessment-results/save-answers', jsonData)
            .then((response) => {
                let data = response.data;
                this.stopEffects();
                if (data.status === 'okay') {
                    let email = document.getElementById('email-field').value;
                    let assessmentForm = document.getElementById('assessment-form');
                    let checkOverlay = document.getElementById('check-overlay-wrapper');
                    checkOverlay.classList.remove(styles.scaleOut);
                    assessmentForm.classList.add(styles.fadeOut);

                    document.getElementById('lazy-image-hd-assessment-bg').classList.add(styles.fadeOut);
                    document.getElementById('submit-text').classList.add(styles.fadeOut);

                    let self = this;

                    setTimeout(() => {
                        self.renderModal('Your Answers Were Submitted!',
                            'Your answers to the assessment were saved. ' +
                            'A link to view and print your results were sent to <a href="#">' + email + '</a>.');
                        assessmentForm.remove();
                    }, 2000);
                } else {
                    this.handleError(data);
                }
            })
            .catch((error) => {
                console.error(error);
                this.renderModal('An unknown error occurred', 'Please report this to your system ' +
                    'administrator. Error details: ' + error);
                this.stopEffects();
            });
    }

    static stopEffects() {
        document.getElementById('assessment-form').classList.remove(styles.sendingFilter);
        document.getElementById('spinner-wrapper').classList.add(styles.fadeOut);
    }

    static getAnswerLevelFromInputs(inputList) {
        for (let i = 0; i < inputList.length; i++) {
            if (inputList[i].checked) {
                return i + 1;
            }
        }

        return -1;
    }

    static renderModal(title, content) {
        let messageModal = document.getElementById('message-modal');
        let messageModalTitle = document.getElementById('message-modal-title');
        let messageModalContent = document.getElementById('message-modal-content');

        messageModalTitle.innerHTML = title;
        messageModalContent.innerHTML = content;

        messageModal.classList.add(styles.isActive);
    }

    static closeModal() {
        let messageModal = document.getElementById('message-modal');
        messageModal.classList.remove(styles.isActive);
        document.getElementById('confirm-button').style.display = 'none';
    }

    static validateAnswers(jsonData, validationMethod) {
        if (!document.getElementById('tos-checkbox').checked) {
            this.renderModal('Accept the Terms and Conditions',
                'Please accept the <a href="https://www.highpoint.education/pages/terms" target="_blank">terms and conditions</a> ' +
                'before you submit the form.');
            return false;
        }

        if (!document.getElementById('type-field').value || document.getElementById('type-field').value === "none") {
            this.renderModal('Missing Role Selection', 'Please click the drop-down and indicate which role best describes you.');
            return false;
        }

        if (!document.getElementById('email-field').value.length ||
            !InputValidationUtil.validateEmail(document.getElementById('email-field').value)) {
            this.renderModal('Missing Email', 'Please enter a valid email address.');
            return false;
        }

        if (!document.getElementById('first-name-field').value.length ||
            !document.getElementById('last-name-field').value.length) {
            this.renderModal('Missing Full Name', 'Please make sure you\'ve listed your first and last name ' +
                'on the form.');
            return false;
        }

        if (!validationMethod(jsonData)) {
            this.renderModal('Missing Answers', 'It looks like you haven\'t fully completed the assessment. ' +
                'Please double check to make sure you\'ve filled in the whole form as well as put your full name ' +
                'and email.');
            return false;
        }

        return true;
    }

    static handleError(data) {
        let status = data.status;
        if (status === 'results on file') {
            let monthsDisplay =
                data.months < 1.0 ? 'less than a month' :
                    data.months === 1.0 ? 'about 1 month' :
                        'about ' + data.months + ' months';
            this.renderModal('Recent Test Results Already on File', 'We found existing test results from ' +
                monthsDisplay + ' ago. Are you sure you\'d like to overwrite them?');
            document.getElementById('confirm-button').style.display = 'inline-flex';
        } else if (status === 'validation failed') {
            this.renderModal('Failed to Validate Answers on Server', 'Your data could not be validated on' +
                ' the server. This may indicate that you have invalid characters in the submission box.');
        } else if (status === 'internal error') {
            this.renderModal('Internal Error', 'We\'re sorry, but an internal error occurred while saving your' +
                ' results. Please check your internet connection and try again or reload the page.');
        } else if (status === 'bad email') {
            this.renderModal('Couldn\'t Send Email', 'Your answers couldn\'t be saved with the email address you provided. ' +
                'If the email you provided is valid, try a different email address and click "View Results" again.');
        }
    }
}