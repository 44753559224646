import React, { Component } from 'react';
import * as styles from '../../../../../styles/assessment.scss';
import PropTypes from 'prop-types';

class SkillAreaDisplay extends Component {
    constructor(props) {
        super(props);

        this.skill = this.props.skill;
        this.score = this.props.score;
        this.type = this.props.type;
        this.family = this.props.family;
        this.definition = this.props.definition;
        this.faDirection = this.type == 'Strong' ? 'up' : 'down';
        this.familyMap = {
            'academic': 'Academic',
            'personal': 'Personal',
            'pre-professional': 'PreProfessional'
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={`${styles.columnCount} ${styles.isMultiline} ${styles.skillContainer} ${styles['crtSkillArea' + this.familyMap[this.family]]}`}>
                    <div className={`${styles.column} ${styles.isFull}`}>
                        <div className={`${styles.skillHeader} ${styles.crtSkillHeaderContainer}`}>
                            <div style={{minWidth: 0}}>
                                <h3>
                                    <span className={`${styles.crtScore}`}>{this.score}</span>
                                </h3>
                                <h3 className={styles.crtSkillName}>{this.skill}</h3>
                            </div>
                            <div className={styles.crtType}>
                                <h3><i className={`fas fa-angle-double-${this.faDirection}`}/>&nbsp;&nbsp;{this.type}</h3>
                            </div>
                        </div>
                        <p>{this.definition}</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

SkillAreaDisplay.propTypes = {
    type: PropTypes.string,
    skill: PropTypes.string,
    definition: PropTypes.string,
    family: PropTypes.string,
    score: PropTypes.number,
};
export default SkillAreaDisplay;