import React, {Component} from 'react';
import * as styles from '../../../styles/assessment.scss';

class LearningResultsContentPartialView extends Component {

    render() {
        return (
            <section className={`${styles.section} ${styles.fullHeight} ${styles.primaryBackground} ${styles.pageMargin}`}>
                <div className={`${styles.columnCount} ${styles.isMultiline} ${styles.widePadding}`} style={{paddingTop: 0}}>
                    <div className={`${styles.column} ${styles.isFull}`}>
                        <h2 style={{textAlign: 'center'}}>
                            How to Read Your Scores
                        </h2>
                        <p className={styles.fontSmall1350}>
                            This is a phenomenological Learning Style Assessment, meaning that it shows you your
                            learning process in your present circumstances—new factors, internal or external, can
                            influence and even change your learning process over time, especially as you respond to
                            your environment and reflect on yourself. Understanding how you take in and process
                            information can help you shape your future and reach your fullest potential. Use this
                            assessment is a tool for self-reflection and a starting point for understanding your
                            present learning style.
                            <br/><br/>
                            <b>What do these terms mean?</b> Your learning style is scored in four distinct areas, each
                            identified by two terms—the first term (Concrete or Abstract) signifies your preferred
                            mode of information intake and the second term (Sequential or Relational) signifies your
                            preferred mode of process.  Regarding modes of information intake, someone with a
                            “Concrete” marker will likely prefer fixed, factual, or well-defined information as a
                            starting point for learning, whereas someone with an “Abstract” marker may prefer
                            theoretical or conceptual information.  With respect to information processing, someone
                            with a “Sequential” marker will digest, understand, and remember what they’ve learned by
                            breaking it down into a logical order or sequence, while someone with a “Relational”
                            marker will digest, understand, and remember what they’ve learned by placing what they’ve
                            learned in a context of relations.  Modes of intake and processing are not mutually
                            exclusive, and so someone might score very high on both the “Abstract” and “Concrete”
                            scales regardless of their mode of process, and very high on both the “Sequential” and
                            “Relational” scales regardless of their mode of intake.
                            <br/><br/>
                            <b>Tested vs. Perceived Scores:</b> When you look at your scores you will see “tested” and
                            “perceived” scores for each area. Your “tested” score identifies your actual learning
                            style, while your “perceived” score shows your perception of how you learn.  Typically,
                            the “tested” and “perceived” scores will be similar, but where they differ significantly
                            it is important to think carefully about the apparent discrepancy and what this means for
                            you.
                            <br/><br/>
                            <b>Primary and Secondary Scores:</b> When you look at your scores, begin by looking at your
                            highest “tested” score—this is your primary learning style.  Your primary learning style
                            is your primary intake-process dynamic, the mode where you feel most “at home” and where
                            you spend most of your time engaging with your environment.  Your primary learning style
                            reflects both your natural learning dispositions and how you’ve adapted to your current
                            learning environment.  In order to understand how you learn and how you respond to
                            challenges, you need to become familiar with your dominant learning style.
                            <br/><br/>
                            While most people will find that they have one primary or dominant learning style, some
                            people (especially those who are adapting to a new environment) may find that they have
                            two or more roughly equal scores.  If this is true for you, then your learning style may
                            be in the process of shifting in response to different factors.  Consider setting up a
                            consultation with one of our <a href="https://www.highpoint.education/pages/coaching">Success
                            Coaches</a> to look more deeply into your learning style and environment.
                            <br/><br/>
                            Now look at your next highest “tested” score—this is your secondary learning style.  If
                            you think of your primary learning style as your “go-to” mode of learning, then you can
                            think of your secondary learning style as your back-up mode, or the learning style you
                            tap into on certain occasions, when something different is required of you, or when your
                            primary learning style just isn’t delivering what you want.  Your secondary learning
                            style also reflects your natural dispositions and, to some extent, how you are adapting
                            to your learning environment.  Depending on where you are in the process of adapting,
                            your secondary learning score may be an emerging area or it may be stable and in
                            reserve.  In either case, it is important to understand the main features of your
                            secondary learning style and how it supports or interacts with your primary learning
                            style.
                            <br/><br/>
                            Finally, look at your lowest scoring areas—these are your tertiary and quaternary
                            learning styles.  These are the learning areas that you would rarely (if ever) access on
                            your own or without being prompted to do so.  In fact, these ways of learning may be
                            uncomfortable for you at present, and they even may stifle your ability to
                            learn—especially if learning content is being communicated to you in the “language” of
                            your tertiary and quaternary learning style.  Our <a href="https://www.highpoint.education/pages/coaching">Success
                            Coaches</a> can help you better understand your scores, how your learning style areas are
                            interacting with one another, and what learning impediments to watch out for based on your
                            learning strengths.
                        </p>
                    </div>
                    <div className={`${styles.column} ${styles.isFull} ${styles.printPageBreakAfter}`}>
                        <h3 className={styles.vCentered}>Tested vs. Perceived Scores</h3>
                        <div id="bar-chart-column" className={`${styles.vCentered} ${styles.halveOnPrint}`}>
                        </div>
                    </div>
                    <div className={`${styles.column} ${styles.isFull}`}>
                        <h2 style={{textAlign: 'center'}}>
                            Primary Learning Style
                        </h2>
                        <div id="primary-learning-style-notice" className={styles.scoreResultsDisplay}>
                        </div>
                        <div id="primary-learning-style-0" className={styles.scoreResultsDisplay}>
                        </div>
                        <div id="primary-learning-style-1" className={styles.scoreResultsDisplay}>
                        </div>
                        <div id="primary-learning-style-2" className={styles.scoreResultsDisplay}>
                        </div>
                        <div id="primary-learning-style-3" className={styles.scoreResultsDisplay}>
                        </div>
                    </div>
                    <div className={`${styles.column} ${styles.isFull} ${styles.printPageBreakAfter}`}>
                        <h3 className={styles.vCentered}>Tested vs. Perceived Scores</h3>
                        <div id="radar-chart-column" className={`${styles.vCentered} ${styles.halveOnPrint}`}>
                        </div>
                    </div>
                    <div className={`${styles.column} ${styles.isFull}`}>
                        <h2 style={{textAlign: 'center'}}>
                            Secondary Learning Style
                        </h2>
                        <div id="secondary-learning-style-notice">
                        </div>
                        <div id="secondary-learning-style-0" className={styles.scoreResultsDisplay}>
                        </div>
                        <div id="secondary-learning-style-1" className={styles.scoreResultsDisplay}>
                        </div>
                        <div id="secondary-learning-style-2" className={styles.scoreResultsDisplay}>
                        </div>
                        <div id="secondary-learning-style-3" className={styles.scoreResultsDisplay}>
                        </div>
                    </div>
                    <div className={`${styles.column} ${styles.isFull}`}>
                        <h2 style={{textAlign: 'center'}}>
                            What do Your Scores Mean?
                        </h2>
                        <p className={styles.fontSmall1350}>
                            Your learning style scores provide you a glimpse into how you learn in your present
                            environment.  They show how you’ve adapted, what core strengths you’ve acquired, and even
                            where your struggles may lie.  But phenomenological scores are always a snapshot, and
                            that’s good news!  It means that your strengths and weaknesses are not fixed or permanent
                            parts of your nature, but are based on dispositions, attitudes, and ways of thinking that
                            you can influence.  By showing you where you are right now, your scores can also show you
                            what else you’re capable of.  In fact, a great deal lies within your control.  You have
                            the power to change the way you engage with the world around you.  Use these scores to
                            begin building a commitment to yourself.
                        </p>
                    </div>
                    <div className={`${styles.column} ${styles.isFull}`}>
                        <h2 style={{textAlign: 'center'}}>
                            What Can You Do with Your Scores?
                        </h2>
                        <p className={styles.fontSmall1350}>
                            Understanding how you learn right now is the first and maybe even the most important step
                            toward taking control of your life and making progress toward your personal and
                            professional goals.  You can use your scores to better understand your learning style, or
                            the way you take in, digest, understand, and remember information.  The main purpose of
                            these scores is to give you the basic tools you need to capitalize on your strengths,
                            avoid pitfalls, and take aim at your goals.
                            <br/><br/>
                            The way you take in and process information impacts just about every part of your life,
                            from the most obvious to the most complex aspects, and what you can do with your scores
                            goes well beyond just advancing your learning ability.  You can use your learning style
                            scores to understand your communication process, including both the way you communicate
                            with other people and how you understand what others communicate to you.  You can use you
                            learning style scores to better understand your social engagements, or how you interact
                            with other people in different group settings.  You can use your learning style to
                            understand your social and emotional support needs, including both how your learning
                            style and your view of the world influence your desire for particular types and levels of
                            emotional contact, and how you tend to support others around you.  Your learning style
                            scores can even be used to help you build strong and meaningful relationships with the
                            people closest to you.
                            <br/><br/>
                            If you’d like to get the most out of your learning assessment, our highly trained staff
                            of coaches is here to help.
                            <a href="https://www.highpoint.education/pages/coaching"> Click here to set up a
                                consultation and learn how you can reach your highpoint!</a>
                            <br/><br/>
                            We’re here to help you with this process! Our Master Courses, the <b>Empowered
                            Student</b> and the <b>Apex Student</b>, can help you make your way through the skills
                            -building process, taking you beyond obvious lessons and into a new experience of your
                            academic and personal potential. <a href="https://www.highpoint.education/collections">Learn
                            more about our courses by clicking HERE.</a>
                        </p>
                    </div>
                </div>
            </section>
        );
    }
}

export default LearningResultsContentPartialView;