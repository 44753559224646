/**
 * Created by Robert on 6/13/19.
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as styles from '../styles/nav.scss';
import logoPng from '../images/highpoint-text-logo.svg';


export default class NavView extends Component {
    componentDidMount() {
        document.addEventListener('DOMContentLoaded', () => {

            // Get all "navbar-burger" elements
            const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

            // Check if there are any navbar burgers
            if ($navbarBurgers.length > 0) {

                // Add a click event on each of them
                $navbarBurgers.forEach(el => {
                    el.addEventListener('click', () => {

                        // Get the target from the "data-target" attribute
                        const target = el.dataset.target;
                        const $target = document.getElementById(target);

                        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                        el.classList.toggle(styles.isActive);
                        $target.classList.toggle(styles.isActive);

                    });
                });
            }

        });
    }

    render() {
        return (
            <div className={styles.hideOnPrint}>
                <nav id="nav" className={styles.navbar} role="navigation" aria-label="main navigation">
                    <div className={`${styles.navbarContainer}`}>
                        <div className={styles.navbarBrandContainer}>
                            <div className={styles.navbarBrand}>
                                <a role="button" className={`${styles.navbarBurger} navbar-burger`} data-target='navbar-main' aria-label="menu" aria-expanded="false">
                                    <span aria-hidden="true"/>
                                    <span aria-hidden="true"/>
                                    <span aria-hidden="true"/>
                                </a>
                                <a className={`${styles.navbarItem} ${styles.navbarLogo}`} href="https://www.highpoint.education">
                                    <img src={logoPng} alt={"Highpoint Academy Logo"}/>
                                </a>
                            </div>
                        </div>

                        <div role="menu" id="navbar-main" className={styles.navbarMenu}>
                            <div className={styles.navbarStart}>
                                <a className={styles.navbarItem} href="https://www.highpoint.education">
                                    Home
                                </a>
                                <a className={styles.navbarItem} href="/learning-assessment/take-test">
                                    Learning Styles Assessment
                                </a>
                                <a className={styles.navbarItem} href="/values-assessment/take-test">
                                    Values Assessment
                                </a>
                                <a className={styles.navbarItem} href="/college-readiness-test/take-test">
                                    College Readiness Test
                                </a>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className={styles.navSpacer}/>
            </div>
        );
    }
}
/**
 * Created by Robert on 6/13/19.
 */
