import { Component } from 'react';
import PropTypes from 'prop-types';

class AssessmentResultsChartView extends Component {
    constructor(props) {
        super(props);
        const { config, tested, perceived } = this.props;
        this.data = [
            {
                'Type': config.scoring.radar.legend.keys[0],
                'Tested': tested[0],
                'Perceived': perceived[0],
            },
            {
                'Type': config.scoring.radar.legend.keys[1],
                'Tested': tested[1],
                'Perceived': perceived[1]
            },
            {
                'Type': config.scoring.radar.legend.keys[2],
                'Tested': tested[2],
                'Perceived': perceived[2],
            },
            {
                'Type': config.scoring.radar.legend.keys[3],
                'Tested': tested[3],
                'Perceived': perceived[3]
            },
        ];
    }
}

AssessmentResultsChartView.propTypes = {
    tested: PropTypes.array,
    perceived: PropTypes.array,
    config: PropTypes.object
};

export default AssessmentResultsChartView;