/**
 * Created by Robert on 6/14/19.
 */
import React, {Component} from 'react';
import * as styles from '../../../../styles/assessment.scss';
import PropTypes from 'prop-types';

class AbstractRelationalPrimaryPartialView extends Component {

    render() {
        return (
            <div>
                <div>
                    <h4 className={styles.learningOutcome}>Abstract Relational (AR): {this.props.score}</h4>
                    <p className={styles.fontSmall1350}>
                        <b>Your dominant mode of information intake is “Abstract.”</b> Dominant Abstract
                        Relational learners prefer abstract, conceptual, and theoretical learning content, and
                        may even be more drawn to emotional, subjective, contextual, or hypothetical starting
                        points. These types of content give them the opportunity to exercise their desire to
                        think on an abstract level, but in ways that play into their imaginative, intuitive, and
                        empathetic abilities.
                        <br/><br/>
                        <b>Your dominant mode of information process is “Relational.”</b> Dominant Abstract
                        Relational learners prefer information that is presented in a relational context showing
                        the interconnections between the primary learning content other perspectives, theories,
                        examples, concepts, etc. that help to co-define or illuminate it.  Abstract Relational
                        learners place new information in context, but they also pay special attention to the
                        contextual framework as a whole as a means of understanding the interconnectedness of
                        different elements of the learning content.
                        <br/><br/>
                        <b>You have some amazing strengths!</b> Abstract Relational learners have an amazing
                        ability to recognize the connections between distantly related objects, issues,
                        perspectives, theories, examples, concepts, etc.  And what’s more, they make these
                        connections with incredible speed and accuracy as if it were a natural or intuitive
                        process.  This ability is an outgrowth of the Abstract Relational perspective on the
                        context of relations surrounding and interconnecting each object of thought.  In other
                        words, where someone else might see these factors as disconnected or unrelated, Abstract
                        Relational learners see context and relationships in a much more fluid sense, and are
                        thus able to recognize and link different things that go unnoticed by other very
                        competent people.  This ability is often at its height when Abstract Relationals are
                        working in interpersonal or emotional contexts where this ability can appear almost on
                        par with psychic intuition (especially in their ability to “read the room”).
                        <br/><br/>
                        Abstract Relational learners are eternal optimists and silver lining specialists.
                        Because they see deeply and clearly into the context of relations surrounding objects,
                        issues, and circumstances, its easy for them to identify potential advantages, gains, and
                        benefits in an otherwise lousy predicament.  The interconnectedness that they see in all
                        things and their personal history of finding the good in a bad situation teaches them to
                        expect some good even when things look ominous.  They’re able to maintain a fairly
                        optimistic disposition on the one hand, and they’re able to patiently and persistently
                        seek out the upside or hidden advantage in a bad situation on the other. Abstract
                        Relationals are sometimes criticized for their cheerful or positive attitudes towards
                        life in general, but they are not naïve—rather, they maintain an outlook on the world
                        that allows them to stay positive when others might struggle to do so.  While it is
                        important for Abstract Relational learners to see objects, issues, and circumstances for
                        what they really are (good or bad), they thrive when they cultivate their ability to
                        remain positive and focused on the good things that are available to them and can propel
                        them toward their goals.
                        <br/><br/>
                        Dominant Abstract Relational learners are “imagineers,” meaning that they are
                        exceptionally creative, passionate, attuned and cooperative.  Because they have the
                        ability to see the greater context of a problem or situation and make connections between
                        things that might otherwise seem only distantly related, they are able to draw people,
                        ideas, concepts, and things together in a way that facilitates a solution.  Abstract
                        Realtional learners are able to cooperate with a wide range of people and see the value
                        that each person brings to the situation in light of their unique way of looking at it.
                        They make others feel heard in the process, and they draw those people and their
                        perspectives into their own thought process.  All of this empowers their creativity and
                        desire to be expressive, and the results of their process are often beautiful and
                        imaginative solutions to problems or artistic expressions of what they see in the world.
                        Abstract Relational learners see these products from all sides, they view the parts in
                        relation to the whole, and as a result they can serve as the grounding voice of
                        conscience and community when necessary.
                        <br/><br/>
                        <b>But, be mindful of these pitfalls!</b> Just as each learning style has a set of clear
                        strengths, each comes with possible difficulties and pitfalls, too.  Sometimes these
                        weaknesses can be reflections of your strengths.  Regardless, these are things to reflect
                        on and be aware of as you build self-understanding and competency.
                        <br/><br/>
                        As we noted above, Dominant Abstract Relational learners have a powerful ability to
                        recognize the connections between distantly related objects, issues, perspectives,
                        theories, examples, concepts, etc., and they can do this with incredible speed and
                        accuracy.  This strength (and it is a strength) can also be a liability in certain
                        situations.  The connections Abstract Relational thinkers make are clear, substantive,
                        and obvious by virtue of their intake-process dynamic.   But these same connections can
                        require great leaps for other learners to understand, and many may still struggle to
                        ground that connection in terms that will make sense in light of their own learning
                        style.  In other words, Abstract Relational thinkers see all the letters of the alphabet
                        laid out before them and can easily jump from A to X; other people might be able to jump
                        from A to D, but can’t make sense of a leap from A to X.  To them there appears to be no
                        connection between A and X at all, and they interpret the Abstract Relational process as
                        “random.” In fact, it is a very common experience for Abstract Relational thinkers to
                        express an observation or identify a connection and be met with utter bewilderment by the
                        person listening to them.  The connections that Abstract Relationals recognize are
                        anything but random, but they don’t appreciate being thought of as “random” and they
                        frequently have a hard time connecting all of the dots in between A and X for other
                        people despite being able to see the connection so clearly themselves.  Here Abstract
                        Relational learners can benefit from being patient with other people who misunderstand
                        them, and from practicing effective communication (especially the ability to give a
                        rational account of their thoughts, feelings, and observations).
                        <br/><br/>
                        Because Abstract Relational learners have the ability to see things in various contexts
                        and recognize distant connections between objects, they tend to remain on abstract level,
                        focusing on ideas, emotions, perspectives, relationships, etc.  They struggle to bring
                        their view of context and relations down to the level of detail.  And as a result, some
                        Abstract Relational thinkers have the habit of missing or neglecting important details,
                        and on a practical level this can amount to forgetting deadlines or dropping the ball on
                        important responsibilities and obligations.  They will often feel very guilty about
                        having let this happen, especially in situations where they believe they’ve disappointed
                        someone important, and this can leave them feeling sad and inadequate.  Fortunately, for
                        Abstract Relational learners there is a two-step solution to this problem. The first step
                        is to build their broad organizational skills so that none of the important things are
                        misplaced or forgotten about even if they periodically let go of details and shift back
                        to the abstract.  The next step is to capitalize on the Abstract Relational inclination
                        to see things in an emotional context.  If tasks and details are nothing but mundane bits
                        on information, Abstract Relationals are more likely overlook them in favor of more
                        “important” (read “abstract” and “contextual”) things.  But, if tasks and details are
                        understood in the context of their emotional connections, they are far more likely to be
                        remembered (e.g., not “my History paper is due on Tuesday,” but “on Tuesday I owe Dr.
                        Jones my History paper, and I don’t want to disappoint him/her.”).  These steps take time
                        and practice, but their impacts can be felt almost immediately.
                        <br/><br/>
                        Some Abstract Relational learners find it very difficult to stay focused on particular
                        objects, tasks, projects, topics, etc., and as a result, they also find it hard to stay
                        on task when they need to get their work done.  This can frequently appear to be an
                        unwillingness or inability to direct their attention to one thing at a time and for any
                        length of time, and Abstract Relational learners are sometimes criticized from being
                        undisciplined or for lacking the determination or “stick-to-ittiveness” to complete
                        anything.  But what appears to be a lack of focus, determination, or grit, is actually a
                        feature of the Abstract Relational intake-process dynamic.  Abstract Relationals take in,
                        digest, understand, and remember what they’ve learned by placing it in context and
                        recognizing the interconnectedness of different elements.  This means that as they learn
                        they are naturally pulled away from their starting point and toward the interconnected
                        framework that sustains it; from there they are able to see innumerable perspectives and
                        cultivate altogether new ideas insights.  For one person the road from their starting
                        point leads straight to a completed task, but for Abstract Relational learners, the road
                        takes them to unexpected (but no less valuable) places—and there may not be a clear end
                        to their process.  Of course, when their learning process hinders their ability to get
                        their work done, this is an issue.  Abstract Relationals benefit from structuring their
                        time and tasks in ways that allow them to come and go from their work while still
                        tracking toward the completion of important tasks.  Again, this takes time and practice,
                        but the results are quickly felt.
                        <br/><br/>
                        <b>Be the “You-est You” You Can Be!</b>  In order to be the fullest version of yourself
                        that you can be, you need to deepen your self-awareness, incrementally overcome your
                        challenges, and fully empower yourself to reach your goals.  Here are more things for you
                        to consider in order to help you on your way:
                    </p>
                    <br/>
                    <br/>
                </div>
                <div className={`${styles.columnCount} ${styles.isMultiline} ${styles.typeMatrix}`}>
                    <div className={`${styles.column} ${styles.isHalf} ${styles.backgroundBanana}`}>
                        <h3>Abstract Relationals don't like:</h3>
                        <hr className={styles.partialHr}/>
                        <p>
                            - detailed, meticulous, and concrete learning content<br/>
                            - restrictive, rule-driven, limited learning environments<br/>
                            - sterile, impersonal, detached, working conditions<br/>
                            - formalized/firm rules, responsibilities, objectives, timelines, deadlines, etc.<br/>
                            - being required to memorize/regurgitate exact details<br/>
                            - learning activities/jobs that require them to be exacting, detail- oriented, and
                            specific<br/>
                            - skeptical demands to account for their intuition or to rationalize their feelings, point
                            of view, insights<br/>
                            - overly strict, dogmatic, logical, restrictive people<br/>
                            - working with people who are insensitive to others’ emotions and/or points of view<br/>
                            - continual criticism and/or criticism that is not delivered with kindness or
                            consideration<br/>
                            - working with people with whom they have no personal connection
                        </p>
                    </div>
                    <div className={`${styles.column} ${styles.isHalf} ${styles.backgroundLime}`}>
                        <h3>Abstract Relationals are nurtured by:</h3>
                        <hr className={styles.partialHr}/>
                        <p>
                            - open and supportive learning environments that promote creativity and sharing of perspectives<br/>
                            - learning spaces that are stimulus rich, inspire deep and associative thinking, but support students’ focus<br/>
                            - group and hands-on learning activities<br/>
                            - being allowed to use their intuition as a valid part of the thinking/learning process<br/>
                            - being presented with multiple perspectives and ways of think about problems and experiences<br/>
                            - being offered variable ways of learning different content<br/>
                            - multi-media, group discussions, reflection exercises<br/>
                            <br/>
                            - teachers and mentors who are open, warm, caring, and supportive; who accept and love them unconditionally<br/>
                            - student-teacher relationships that permit and promote the free exchange of ideas, perspectives, and emotions<br/>
                            - supportive, cooperative, non-competitive groups<br/>
                            - teachers and mentors who praise and reward their creativity and intuition, but who kindly hold them accountable and provide constructive criticism
                        </p>
                    </div>
                    <div className={`${styles.column} ${styles.isHalf} ${styles.backgroundApple}`}>
                        <h3>Abstract Relationals worry about:</h3>
                        <hr className={styles.partialHr}/>
                        <p>
                            - being thought of as flaky, unreliable, or incompetent<br/>
                            - being written off as crazy, “random,” or eccentric because of their insights, imaginative ideas, and their ability to make connections<br/>
                            - being unworthy of other people’s love and recognition, being isolated from other people who care about them<br/>
                            - being able to live up to other people’s expectations (esp. when expected to be detailed or sequential)<br/>
                            - meeting high academic standards<br/>
                            - not being well-liked by everyone<br/>
                            - being managed by overbearing, unkind, inconsiderate people and/or perfectionists
                        </p>
                    </div>
                    <div className={`${styles.column} ${styles.isHalf} ${styles.backgroundBlueberry}`}>
                        <h3>Abstract Relationals develop when:</h3>
                        <hr className={styles.partialHr}/>
                        <p>
                            - they refine their intuition and emotional insights
                            - they learn to be more discriminating
                            - they given themselves permission to be their full creative and expressive selves
                            - intentionally cultivate their organizational and time- and task-management skills
                            - they learn to grasp rules, restrictions, limitations
                            - they accept established rules and parameters as part of the challenge of problem-solving
                            - they learn to work on schedule, meet deadlines, and break habits of procrastination and avoidance
                            - they practice self-acceptance (esp. in the face of skeptics, pessimists, and perfectionists)
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

AbstractRelationalPrimaryPartialView.propTypes = {
    score: PropTypes.number
};

export default AbstractRelationalPrimaryPartialView;