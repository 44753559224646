import React, { Component } from 'react';
import * as styles from '../../../../styles/assessment.scss';

class CrtSeverelyUnderpreparedPartialView extends Component {

    render() {
        return (
            <React.Fragment>
                <h4 className={styles.crtSeverelyUnderprepared}>25-59 Severely Underprepared</h4>
                <p>An aggregate score between 25 and 59 indicates a critical lack of skills preparedness. Students in this score range
                    may find they lack the basic skills necessary to negotiate and be successful in their college or university classes.
                    Students with this level of skills deficiency will struggle in the initial phase
                    of college- or university-level education, which can result in significant academic underperformance (e.g., first-year
                    GPA below 2.0); more than 80% of students in this performance category leave their college or university after their
                    first year.
                    <br/><br/>
                    For many students and families, leaving college due to unpreparedness and underperformance is costly and disheartening.
                    But these are absolutely avoidable consequences. Even students with severe skills deficiencies can course-correct,
                    acquire requisite skills, and overcome their challenges if they have the proper support. And this can happen
                    in less time that you might think. Supporting students in this space is one of our specialties, and one where our
                    passionate commitment to student success shines. Our Master Courses and Success Coaching options provide a
                    comprehensive approach to address any and all areas of concern. Learn more <a href="https://www.highpoint.education/collections">HERE</a>.
                    <br/><br/>
                    If you have concerns about your score, about our products, or about the timeframe wherein you need to see results,
                    contact us directly at info@highpoint.education to learn more about how we can meet your needs.
                </p>
            </React.Fragment>
        );
    }
}

export default CrtSeverelyUnderpreparedPartialView;