import React, { Component } from 'react';
import * as styles from '../../../styles/assessment.scss';
import config from '../../../config/assessment/crt/questions.json';
import DataProcessorUtil from '../../../../common/utility/data-processor.util';

class CollegeReadinessTestQuestionsView extends Component {
    constructor(props) {
        super(props);

        this.questionsContainer = [];
        this.questions = renderQuestions();
    }

    componentDidMount() {
        if (this.questionsContainer.length === 0) {
            this.questionsContainer.push(this.questions);
        }

        // const crtRadioButtons = document.getElementsByClassName(styles.formRadio);
        // const onRadioButtonClick = (event) => {
        //     const radioButton = event.target;
        //     const container = document.getElementById(
        //         `answers-container-question-${radioButton.dataset.question}`);
        //     container.innerHTML = '<h4><i>' + config['answer levels'][radioButton.dataset.level] + '</i></h4>';
        // };
        //
        // for (let i = 0; i < crtRadioButtons.length; i++) {
        //     const radioButton = crtRadioButtons[i];
        //     radioButton.addEventListener('click', onRadioButtonClick);
        // }
    }

    render() {
        return (
            <div>
                <div id="questions-container">
                    {this.questionsContainer}
                </div>
            </div>
        );
    }
}

/* Randomly renders the questions onto the page. First selects a random category and then selects a random question
* from that category.
*/
function renderQuestions() {
    const elementArray = [];
    // We need to clone the object otherwise it will be deleted from the config. The following is a quick way to do so.
    let categories = JSON.parse(JSON.stringify(config.categories));
    for (let i = 0; i < categories.length; i++) {
        let categoryData = categories[i];
        let category = categoryData.title;
        const colorClass = (i % 2 == 0 ? styles.backgroundCrtDarker : styles.backgroundCrt);

        elementArray.push(
            <section key={'crtq-' + (i + 1)} id={'question-' + (i + 1)}
                     className={`${styles.section} ${styles.crtSection} ${styles.paragraphQuestionSection} ${colorClass}`}
                     data-category={category}>
                <div className={`${styles.columnCount} ${styles.isMultiline}`}>
                    <div className={`${styles.column} ${styles.isFull}`}>
                        <h4 className={styles.crtH4}>{categoryData.title}</h4>
                    </div>
                </div>
                <div
                    className={`${styles.crtColumnContainer} ${styles.isMultiline} ${styles.questionsMultilineHelper}`}>
                    <div className={`${styles.crtColumn} ${styles.vCentered} ${styles.fontSmall1100} ${styles.fontSmallMobile}`}>
                        <p>
                            {categoryData.descriptions[0]}
                        </p>
                    </div>
                    <div className={`${styles.crtColumn} ${styles.vCentered} ${styles.fontSmall1100} ${styles.fontSmallMobile}`}>
                        <p>
                            {categoryData.descriptions[1]}
                        </p>
                    </div>
                    <div className={`${styles.crtColumn} ${styles.vCentered} ${styles.fontSmall1100} ${styles.fontSmallMobile}`}>
                        <p>
                            {categoryData.descriptions[2]}
                        </p>
                    </div>
                </div>
                <div className={`${styles.column} ${styles.isFull} ${styles.crtAnswersContainer} ${styles.vCentered}`}>
                    {getAnswerOptions(i + 1)}
                </div>
            </section>);
    }

    return elementArray;
}

function getAnswerOptions(question) {
    const inputs = [];

    for (let i = 0; i < 5; i++) {
        const identifier = 'question-' + question;
        const numberClass = styles['formRadio' + (i + 1)];
        inputs.push(<input
            className={`${styles.formRadio} ${numberClass}`}
            key={'crt-' + question + '-' + i}
            type="radio"
            name={identifier}
            data-level={i}
            data-question={question}/>);
    }

    return inputs;
}

export default CollegeReadinessTestQuestionsView;