/**
 * Created by Robert on 6/25/19.
 */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import * as styles from '../../../../styles/assessment.scss';
import axios from 'axios';
import resultsConfig from '../../../../config/assessment/crt/results.json';
import questionsConfig from '../../../../config/assessment/crt/questions.json';
import CrtResultsContentPartialView from '../../../../config/assessment/crt/crt-results-content.partial.view';
import CollegeReadinessTestBarView from './components/college-readiness-test-bar.view';
import DataProcessorUtil from '../../../../../common/utility/data-processor.util';
import CrtPreparedPartialView from '../../../../config/assessment/crt/score-ranges/crt-prepared.partial.view';
import CrtSeverelyUnderpreparedPartialView from '../../../../config/assessment/crt/score-ranges/crt-severely-underprepared.partial.view';
import CrtUnderpreparedPartialView from '../../../../config/assessment/crt/score-ranges/crt-underprepared.partial.view';
import CrtModeratelyPreparedPartialView from '../../../../config/assessment/crt/score-ranges/crt-moderately-prepared.partial.view';
import SkillAreaDisplay from './components/skill-area-display';

class CollegeReadinessTestResultsView extends Component {
    render() {
        let token = getUrlVars()['token'];

        if (token == null) {
            this.props.history.push(`/404`);
            return null;
        }

        return (
            <section id='fade-section' className={`${styles.fadeWrapper} ${styles.fadeOut}`}>
                <section id='results-section' className={`${styles.section} ${styles.vCentered} ${styles.crtBackground} ${styles.whiteText} ${styles.pageMargin}`}>
                    <div className={`${styles.columnCount} ${styles.isMultiline}`}>
                        <div className={`${styles.column} ${styles.isFull} ${styles.vCentered}`} id='results-header'>
                        </div>
                    </div>
                </section>
                <CrtResultsContentPartialView/>
            </section>
        );
    }

    async componentDidMount() {
        let responseDocument = await getResponseDocument();
        if (responseDocument == null) {
            this.props.history.push(`/404`);
            return null;
        }

        this.headerView = (<h1>Your results are in, {responseDocument.user[0].firstName}!</h1>);

        if (window.innerWidth < 1350) {
            this.headerView = (
                <h2 style={{textAlign: 'center'}}>Your results are<br/>in, {responseDocument.user[0].firstName}!</h2>);
        }

        ReactDOM.render(this.headerView,
            document.getElementById('results-header'));

        const categories = responseDocument.assessment[0].answers[0].categories;
        let categoryMap = mapCategories(categories);
        for (const [family, categories] of Object.entries(categoryMap)) {
            const view = <CollegeReadinessTestBarView
                family={family}
                categories={categories}
                questionsConfig={questionsConfig}
                resultsConfig={resultsConfig}
            />
            ReactDOM.render(view, document.getElementById(family + '-column'));
        }

        const aggregate = calculateAggregate(categories);
        document.getElementById('aggregate-score').innerHTML = aggregate.toString();

        let scoreRangeView = (<CrtPreparedPartialView/>);
        let numDisplay = 3;
        if (aggregate < 60) {
            scoreRangeView = (<CrtSeverelyUnderpreparedPartialView/>);
            numDisplay = 5;
        } else if (aggregate < 80) {
            scoreRangeView = (<CrtUnderpreparedPartialView/>);
            numDisplay = 5;
        } else if (aggregate < 100) {
            scoreRangeView = (<CrtModeratelyPreparedPartialView/>);
        }

        ReactDOM.render(scoreRangeView, document.getElementById('score-range'));

        // Have to explicitly add a case for equality because different browsers handle the sorting in different ways... apparently...
        const sortedEntries = [...Object.entries(categories)].sort((a, b) => (a[1] === b[1] || a[1] < b[1]));

        const definitionMap = {};
        const familyMap = {};
        for(const categoryData of questionsConfig.categories) {
            definitionMap[categoryData.title] = categoryData.definition;
            familyMap[categoryData.title] = categoryData.family;
        }

        const strongest = sortedEntries.slice(0, numDisplay);
        const weakest = sortedEntries.slice(sortedEntries.length - numDisplay, sortedEntries.length);

        const strongestSkillDisplays = [];
        const weakestSkillDisplays = [];
        let i = 0;
        for (const strongestSkill of strongest) {
            strongestSkillDisplays.push(
                <SkillAreaDisplay
                    key={i++}
                    type={'Strong'}
                    skill={strongestSkill[0]}
                    score={strongestSkill[1]}
                    family={familyMap[strongestSkill[0]]}
                    definition={definitionMap[strongestSkill[0]]}
                />)
        }

        for (const weakestSkill of weakest) {
            weakestSkillDisplays.push(
                <SkillAreaDisplay
                    key={i++}
                    type={'Weak'}
                    skill={weakestSkill[0]}
                    score={weakestSkill[1]}
                    family={familyMap[weakestSkill[0]]}
                    definition={definitionMap[weakestSkill[0]]}
                />)
        }

        ReactDOM.render(strongestSkillDisplays, document.getElementById('strongest-skill-areas'));
        ReactDOM.render(weakestSkillDisplays, document.getElementById('weakest-skill-areas'));

        document.getElementById('fade-section').classList.remove(styles.fadeOut);

        window.addEventListener('resize', () => resizeItems());
        resizeItems();
    }
}

function mapCategories(categories) {
    const categoryMap = {'academic': {}, 'personal': {}, 'pre-professional': {}};
    for (const categoryData of questionsConfig.categories) {
        const family = categoryData.family;
        const identifier = categoryData.title;
        categoryMap[family][categoryData.title] = categories[identifier];
    }
    return categoryMap;
}

function calculateAggregate(answers) {
    let sum = 0;
    for (const [, value] of Object.entries(answers)) {
        sum += value;
    }
    return sum;
}

async function getResponseDocument() {
    return axios.post('/api/assessment-results/get-answers/crt', {token: getUrlVars()['token']})
        .then((response) => {
            let data = response.data;
            if (data == null || data.document == null || data.status !== 'good') {
                return null;
            }

            return data.document;
        })
        .catch((error) => {
            console.error(error);
            return null;
        });
}

function resizeItems() {
    let element = document.getElementById('results-bar');
    let windowWidth = window.innerWidth;
    let calculated = ((windowWidth / 2559)) + (1300/windowWidth * 0.1);

    if (calculated > 1) {
        calculated = 1;
    }

    element.style.transform = `scale(${calculated})`;
    let percent = 10;

    element.style.transformOrigin = `0 0`;

}

function getUrlVars() {
    let vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
        (m, key, value) => {
            vars[key] = value;
        });
    return vars;
}

export default CollegeReadinessTestResultsView;