/**
 * Created by Robert on 6/25/19.
 */
import React, {Component} from 'react';
import preloadBg from '../../../images/learning-assessment/submit-bg-blur.jpg';
import fullBg from '../../../images/learning-assessment/submit-bg.jpg';
import config from '../../../config/assessment/learning/questions';
import 'whatwg-fetch';
import 'babel-polyfill';
import InputValidationUtil from '../../../../common/utility/input-validation.util';
import {FormHandler} from '../../../components/form-handler';
import AssessmentSubmissionForm from '../../assessment-submission-form.view';
import * as styles from '../../../styles/assessment.scss';

class LearningAssessmentSubmitView extends Component {
    render() {
        return (
            <AssessmentSubmissionForm
                preloadBg={preloadBg}
                fullBg={fullBg}
                submitAnswersOverwrite={submitAnswersOverwrite}
                submitAnswersNoOverwrite={submitAnswersNoOverwrite}>
            </AssessmentSubmissionForm>
        );

        function submitAnswers(overwrite) {
            FormHandler.closeModal();

            let jsonData = {
                'type': 'learning',
                'part 1': {
                    'answers': []
                },
                'part 2': {
                    'answers': []
                },
                'user': {
                    'first': document.getElementById('first-name-field').value,
                    'last': document.getElementById('last-name-field').value,
                    'email': document.getElementById('email-field').value,
                    'type': document.getElementById('type-field').value,
                    'do overwrite': overwrite
                }
            };

            const questionList = config['part 1']['questions'];

            for (let i = 0; i < config['part 1']['questions'].length; i++) {
                const answersLists = [];

                for (let j = 0; j < questionList[i].split(', ').length; j++) {
                    let elementIdentifier = 'part-1-group-' + (i + 1) + '-question-' + (j + 1);
                    let answerLevel = FormHandler.getAnswerLevelFromInputs(document.getElementsByName(elementIdentifier));
                    answersLists.push(answerLevel);
                }

                jsonData['part 1']['answers'].push(answersLists);
            }

            for (let k = 0; k < config['part 2']['questions'].length; k++) {
                const elementIdentifier = 'part-2-group-1-question-' + (k + 1);
                const answerLevel = FormHandler.getAnswerLevelFromInputs(document.getElementsByName(elementIdentifier));
                jsonData['part 2']['answers'].push(answerLevel);
            }

            if (!FormHandler.validateAnswers(jsonData, InputValidationUtil.validateAnswersLearning)) {
                return;
            }

            document.getElementById('assessment-form').classList.add(styles.sendingFilter);
            document.getElementById('spinner-wrapper').classList.remove(styles.fadeOut);

            FormHandler.postResults(jsonData);
        }

        function submitAnswersOverwrite() {
            submitAnswers(true);
        }

        function submitAnswersNoOverwrite() {
            submitAnswers(false);
        }
    }
}

export default LearningAssessmentSubmitView;