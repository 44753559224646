/**
 * Created by Robert on 6/15/19.
 */
import React from 'react';
import AssessmentView from '../views/assessment.view';
import NotFoundView from '../views/notfound.view';
import LearningAssessmentResultsView
    from '../views/assessments/learning-assessment/results/learning-assessment-results.view';
import ValuesAssessmentResultsView
    from '../views/assessments/values-assessment/results/values-assessment-results.view';
import CollegeReadinessTestResultsView
    from '../views/assessments/college-readiness-test/results/college-readiness-test-results.view';
import {ASSESSMENT_TYPES} from '../../common/constants';
import {FEATURE_FLAGS, isFlagEnabled} from '../../common/flags';
import {Switch, Route, Redirect} from 'react-router-dom';

const Routing = () => {
    const renderLearningAssessmentView =
        (props) => <AssessmentView {...props} assessmentType={ASSESSMENT_TYPES.LEARNING_STYLE}/>;
    const renderValuesAssessmentView =
        (props) => <AssessmentView {...props} assessmentType={ASSESSMENT_TYPES.VALUES}/>;
    const renderCollegeReadinessTestView =
        (props) => <AssessmentView {...props} assessmentType={ASSESSMENT_TYPES.CRT}/>;
    return (
        <Switch>
            <Route path="/learning-assessment/take-test" render={renderLearningAssessmentView}/>
            <Route path="/values-assessment/take-test" render={renderValuesAssessmentView}/>
            <Route path="/college-readiness-test/take-test" render={renderCollegeReadinessTestView}/>
            <Route path="/learning-assessment/view-results" component={LearningAssessmentResultsView}/>
            <Route path="/values-assessment/view-results" component={ValuesAssessmentResultsView}/>
            <Route path="/college-readiness-test/view-results" component={CollegeReadinessTestResultsView}/>
            <Redirect from="/index" to="/learning-assessment/take-test" />
            <Redirect exact from="/" to="/learning-assessment/take-test" />
            <Route path="*" component={NotFoundView}/> {/* Default */}
        </Switch>
    );
};

export default Routing;
