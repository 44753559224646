/**
 * Created by Robert on 6/25/19.
 */
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import * as styles from '../../../../styles/assessment.scss';
import LearningAssessmentResultsRadarView from './learning-assesment-results-radar.view';
import LearningAssessmentResultsBarView from './learning-assesment-results-bar.view';
import config from '../../../../config/assessment/learning/results.json';
import axios from 'axios';
import ConcreteSequentialPrimaryPartialView
    from '../../../../config/assessment/learning/primaries/concrete-sequential-primary.partial.view';
import AbstractSequentialPrimaryPartialView
    from '../../../../config/assessment/learning/primaries/abstract-sequential-primary.partial.view';
import AbstractRelationalPrimaryPartialView
    from '../../../../config/assessment/learning/primaries/abstract-relational-primary.partial.view';
import ConcreteRelationalPrimaryPartialView
    from '../../../../config/assessment/learning/primaries/concrete-relational-primary.partial.view';
import ConcreteSequentialSecondaryPartialView
    from '../../../../config/assessment/learning/secondaries/concrete-sequential-secondary.partial.view';
import AbstractSequentialSecondaryPartialView
    from '../../../../config/assessment/learning/secondaries/abstract-sequential-secondary.partial.view';
import AbstractRelationalSecondaryPartialView
    from '../../../../config/assessment/learning/secondaries/abstract-relational-secondary.partial.view';
import ConcreteRelationalSecondaryPartialView
    from '../../../../config/assessment/learning/secondaries/concrete-relational-secondary.partial.view';
import LearningResultsContentPartialView
    from '../../../../config/assessment/learning/learning-results-content.partial.view';

class LearningAssessmentResultsView extends Component {
    render() {
        let token = getUrlVars()['token'];

        if (token == null) {
            this.props.history.push(`/404`);
            return null;
        }

        return (
            <section id="fade-section" className={`${styles.fadeWrapper} ${styles.fadeOut}`}>
                <section id="results-section" className={`${styles.section} ${styles.vCentered} ${styles.learningBackground} ${styles.whiteText} ${styles.pageMargin}`}>
                    <div className={`${styles.columnCount} ${styles.isMultiline}`}>
                        <div className={`${styles.column} ${styles.isFull} ${styles.vCentered}`} id="results-header">
                        </div>
                    </div>
                </section>
                <LearningResultsContentPartialView/>
            </section>
        );
    }

    async componentDidMount() {
        let responseDocument = await axios.post('/api/assessment-results/get-answers/learning', {token: getUrlVars()['token']})
            .then((response) => {
                let data = response.data;
                if (data == null || data.document == null || data.status !== 'good') {
                    return null;
                }

                return data.document;
            })
            .catch((error) => {
                console.error(error);
                return null;
            });

        if (responseDocument == null) {
            this.props.history.push(`/404`);
            return null;
        }

        let testedArray = getTestedArray(responseDocument.answers[0].part1);
        let perceivedArray = getPerceivedArray(responseDocument.answers[0].part2);

        this.headerView = (<h1>Your results are in, {responseDocument.user[0].firstName}!</h1>);

        if (window.innerWidth < 1350) {
            this.headerView = (
                <h2 style={{textAlign: 'center'}}>Your results are<br/>in, {responseDocument.user[0].firstName}!</h2>);
        }

        ReactDOM.render(this.headerView,
            document.getElementById('results-header'));

        this.radarView = (<LearningAssessmentResultsRadarView
            tested={testedArray}
            perceived={perceivedArray}
            config={config}
        />)
        ;
        ReactDOM.render(this.radarView, document.getElementById('radar-chart-column'));

        this.barView = (<LearningAssessmentResultsBarView
            tested={testedArray}
            perceived={perceivedArray}
            config={config}
        />);
        ReactDOM.render(this.barView, document.getElementById('bar-chart-column'));

        document.getElementById('fade-section').classList.remove(styles.fadeOut);

        window.addEventListener('resize', () => resizeItems());
        resizeItems();

        addTestedSection(testedArray);
    }
}

function addTestedSection(testedArray) {
    let highestIndex = 0;
    let highestIndexes = [];
    let secondHighestIndex = -1;
    let secondHighestIndexes = [];

    for (let currentIndex = 0; currentIndex < testedArray.length; currentIndex++) {
        if (testedArray[currentIndex] > testedArray[highestIndex]) {
            highestIndex = currentIndex;
        }
    }

    highestIndexes += [highestIndex];

    for (let secondPassIndex = 0; secondPassIndex < testedArray.length; secondPassIndex++) {
        if (secondPassIndex != highestIndex && testedArray[secondPassIndex] == testedArray[highestIndex]) {
            highestIndexes += [secondPassIndex];
        }
    }

    secondaryCheck: for (let currentIndex = 0; currentIndex < testedArray.length; currentIndex++) {
        for (let chosenHighestIndex of highestIndexes) {
            if (chosenHighestIndex == currentIndex) {
                continue secondaryCheck;
            }
        }

        if (secondHighestIndex == -1 || testedArray[currentIndex] > testedArray[secondHighestIndex]) {
            secondHighestIndex = currentIndex;
        }
    }

    secondHighestIndexes += [secondHighestIndex];

    for (let thirdPassIndex = 0; thirdPassIndex < testedArray.length; thirdPassIndex++) {
        if (thirdPassIndex != secondHighestIndex && testedArray[thirdPassIndex] == testedArray[secondHighestIndex]) {
            secondHighestIndexes += [thirdPassIndex];
        }
    }

    if (highestIndexes.length > 1) {
        ReactDOM.render(
            <p>
                <b className={`${styles.multipleScoresNote}`}>Note: </b> Your score indicated you have multiple primary learning
                styles. Keep scrolling to see where your scores tied.
                <br/><br/>
            </p>, document.getElementById('primary-learning-style-notice'));
    }

    for (let lastHighestIndex of highestIndexes) {
        let toRender = null;

        if (lastHighestIndex == 0) {
            toRender = (<ConcreteSequentialPrimaryPartialView score={testedArray[lastHighestIndex]}>
            </ConcreteSequentialPrimaryPartialView>);
        } else if (lastHighestIndex == 1) {
            toRender = (<AbstractSequentialPrimaryPartialView score={testedArray[lastHighestIndex]}>
            </AbstractSequentialPrimaryPartialView>);
        } else if (lastHighestIndex == 2) {
            toRender = (<AbstractRelationalPrimaryPartialView score={testedArray[lastHighestIndex]}>
            </AbstractRelationalPrimaryPartialView>);
        } else if (lastHighestIndex == 3) {
            toRender = (<ConcreteRelationalPrimaryPartialView score={testedArray[lastHighestIndex]}>
            </ConcreteRelationalPrimaryPartialView>);
        }

        ReactDOM.render(toRender, document.getElementById('primary-learning-style-' + lastHighestIndex));
    }

    if (secondHighestIndexes.length > 1) {
        ReactDOM.render(
            <p>
                <b className={`${styles.multipleScoresNote}`}>Note: </b> Your score indicated you have multiple secondary learning
                styles. Keep scrolling to see where your scores tied.
                <br/><br/>
            </p>, document.getElementById('secondary-learning-style-notice'));
    }

    for (let lastSecondHighestIndex of secondHighestIndexes) {
        let toRender = null;
        if (lastSecondHighestIndex == 0) {
            toRender = (<ConcreteSequentialSecondaryPartialView score={testedArray[lastSecondHighestIndex]}>
            </ConcreteSequentialSecondaryPartialView>);
        } else if (lastSecondHighestIndex == 1) {
            toRender = (<AbstractSequentialSecondaryPartialView score={testedArray[lastSecondHighestIndex]}>
            </AbstractSequentialSecondaryPartialView>);
        } else if (lastSecondHighestIndex == 2) {
            toRender = (<AbstractRelationalSecondaryPartialView score={testedArray[lastSecondHighestIndex]}>
            </AbstractRelationalSecondaryPartialView>);
        } else if (lastSecondHighestIndex == 3) {
            toRender = (<ConcreteRelationalSecondaryPartialView score={testedArray[lastSecondHighestIndex]}>
            </ConcreteRelationalSecondaryPartialView>);
        }

        ReactDOM.render(toRender, document.getElementById('secondary-learning-style-' + lastSecondHighestIndex));
    }
}

function resizeItems() {
    let elements = document.getElementsByClassName('results-nivo-container');
    let windowWidth = window.innerWidth;
    let calculated = (windowWidth / 1335) + 0.1;

    if (calculated > 1) {
        calculated = 1;
    }

    for (let i = 0; i < elements.length; i++) {
        elements[i].style.transform = `scale(${calculated})`;
    }
}

function getUrlVars() {
    let vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
        (m, key, value) => {
            vars[key] = value;
        });
    return vars;
}

function getTestedArray(answers) {
    let concreteSequential = 0;
    let abstractSequential = 0;
    let abstractRelational = 0;
    let concreteRelational = 0;

    for (let questionSet of answers) {
        concreteSequential += questionSet[0];
        abstractSequential += questionSet[1];
        abstractRelational += questionSet[2];
        concreteRelational += questionSet[3];
    }

    return [concreteSequential, abstractSequential, abstractRelational, concreteRelational];
}

function getPerceivedArray(answers) {
    let concreteSequential = 0;
    let abstractSequential = 0;
    let abstractRelational = 0;
    let concreteRelational = 0;

    for (let i = 0; i < answers.length; i++) {
        switch (i % 4) {
        case 0:
            concreteSequential += answers[i];
            break;
        case 1:
            abstractSequential += answers[i];
            break;
        case 2:
            abstractRelational += answers[i];
            break;
        case 3:
            concreteRelational += answers[i];
            break;
        }
    }

    return [concreteSequential * 5, abstractSequential * 5, abstractRelational * 5, concreteRelational * 5];
}

export default LearningAssessmentResultsView;