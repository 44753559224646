/**
 * Created by Robert on 6/25/19.
 */
import React from 'react';
import {Bar} from '@nivo/bar';
import AssessmentResultsChartView from '../../components/asessment-results-chart-view';
import PropTypes from 'prop-types';
import * as styles from '../../../../styles/assessment.scss';

class LearningAssessmentResultsBarView extends AssessmentResultsChartView {
    constructor(props) {
        super(props);
    }

    render() {
        const { config } = this.props;
        return (
            <div className={'results-nivo-container'}>
                <Bar
                    groupMode="grouped"
                    width={1000}
                    height={1000}
                    margin={{top: 10, right: 150, bottom: 150, left: 150}}
                    data={this.data}
                    indexBy="Type"
                    keys={['Perceived', 'Tested']}
                    colors={config['scoring']['radar']['legend']['colors']}
                    maxValue="75"
                    borderWidth={2}
                    borderColor={{from: 'color'}}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    isInteractive={true}
                    axisBottom={{
                        tickRotation: (this.props.height > 600) ? 0 : -45
                    }}
                    theme={{
                        axis: {
                            fontSize: '16px',
                            ticks: {
                                text: {
                                    fontSize: 16
                                }
                            },
                            legend: {
                                text: {
                                    fontSize: 16
                                }
                            },
                        }
                    }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                />
            </div>
        );
    }
}

LearningAssessmentResultsBarView.propTypes = {
    config: PropTypes.object
};

export default LearningAssessmentResultsBarView;