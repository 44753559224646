class InputValidationUtil {
    static validateEmail(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    static validateAnswersLearning(answers) {
        for (let answer of [...[].concat(...answers['part 1']['answers']), ...answers['part 2']['answers']]) {
            if (answer === -1) {
                return false;
            }
        }

        return true;
    }

    static validateAnswersValues(jsonData) {
        const answers = jsonData['raw question data'];
        let i = 1;
        let identifier = 'question-' + i;
        while (answers[identifier] != null) {
            if (answers[identifier] === -1) {
                return false;
            }

            identifier = 'question-' + ++i;
        }

        return true;
    }

    static validateAnswersCrt(categories) {
        for(const [, value] of Object.entries(categories)) {
            if(value === -1) {
                return false;
            }
        }

        return true;
    }
}

export default InputValidationUtil;