import React, {Component} from 'react';
import './App.css';
import HeaderView from './views/header.view.js';
import Routing from './components/routing';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
        this.loadPage = this.loadPage.bind(this);
    }

    render() {
        return this.loadPage();
    }

    componentDidMount() {
        setTimeout(() => {
            const pageLoadingOverlay = document.getElementById('page-loading-overlay');
            if (pageLoadingOverlay.offsetWidth <= 0 || pageLoadingOverlay.offsetHeight <= 0) {
                const ploBg = document.getElementById('plo-bg');
                const fadeEffect = setInterval(() => {
                    if (!ploBg.style.opacity) {
                        ploBg.style.opacity = 1;
                    }
                    if (ploBg.style.opacity > 0) {
                        ploBg.style.opacity -= 0.1;
                    } else {
                        clearInterval(fadeEffect);
                    }
                }, 200);

                const ploLogo = document.getElementById('plo-logo');
                ploLogo.classList.add('plo-logo-scale-out');

                setTimeout(() => {
                    pageLoadingOverlay.style.display = 'none';
                }, 1000);
            }

            this.setState({loading: false});
        });
    }

    loadPage() {
        return (
            <div>
                <HeaderView/>
                <Routing/>
            </div>
        );
    }
}

export default App;
