import React from 'react';
import * as styles from '../styles/lazyimage.scss';
import PropTypes from 'prop-types';

const LazyImage = (props) => {
    const hdLoaderImg = new Image();

    hdLoaderImg.src = props.srcLoaded;

    hdLoaderImg.onload = () => {
        const preloadId = 'lazy-image-preload-' + props.id;
        const hdId = 'lazy-image-hd-' + props.id;

        const lazyImageHd = document.getElementById(hdId);

        lazyImageHd.setAttribute(
            'style',
            `background-image: url('${props.srcLoaded}')`
        );
        lazyImageHd.classList.add(styles.lazyImageFadeIn);

        if (props.bg) {
            lazyImageHd.classList.add(styles.lazyImageBackground);
        }

        const lazyImagePreload = document.getElementById(preloadId);

        setTimeout(() => {
            if (lazyImagePreload && lazyImagePreload.parentNode) {
                lazyImagePreload.parentNode.removeChild(lazyImagePreload);
            }
        }, 1000);
    };

    return (
        <div className={props.className}>
            <div
                id={'lazy-image-hd-' + props.id}
                className={styles.lazyImageLoaded}
            >
            </div>
            <div
                id={'lazy-image-preload-' + props.id}
                className={styles.lazyImagePreload}
                style={{backgroundImage: `url('${props.srcPreload}')`}}>
            </div>
        </div>
    );
};

LazyImage.propTypes = {
    srcLoaded: PropTypes.string,
    bg: PropTypes.bool,
    className: PropTypes.string,
    srcPreload: PropTypes.string,
    id: PropTypes.string
};

export default LazyImage;
