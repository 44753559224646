/**
 * Created by Robert on 6/14/19.
 */
import React, {Component} from 'react';
import * as styles from '../../../../styles/assessment.scss';
import PropTypes from 'prop-types';

class AbstractRelationalSecondaryPartialView extends Component {

    render() {
        return (
            <div>
                <div>
                    <h4 className={styles.learningOutcome}>Abstract Relational (AR): {this.props.score}</h4>
                    <p className={styles.fontSmall1350}>
                        A secondary Abstract Relational score indicates that you have the capacity to engage
                        with abstract, conceptual, and theoretical learning content (you may even be more drawn
                        to emotional, subjective, contextual, or hypothetical starting points) and the ability to
                        see deeply and clearly into the relational context (e.g., interconnectedness of
                        perspectives, theories, examples, concepts, etc.) that co-defines or illuminates what
                        you’ve learned.  In this mode you are especially imaginative, intuitive, and empathetic.
                        <br/><br/>
                        In your Abstract Relational capacity you have the ability to recognize connections
                        between distantly related objects, issues, perspectives, theories, examples, and
                        concepts, and what’s more, you can make these connections with speed and accuracy as if
                        they were intuitive.  Your ability to do this may be at its height when you’re in social,
                        interpersonal, or emotional contexts where you’re able to use your intuition to “read the
                        room.”  In this mode you are highly perceptive and sensitive, and especially creative or
                        expressive.
                        <br/><br/>
                        Your secondary learning style can be especially important to you when your environment or
                        circumstances ask things of you that outstrip the strengths and abilities of your primary
                        learning style.  You are most likely to tap into this secondary learning style if you
                        find yourself in situations or circumstances where you are called to be attentive to
                        others, where you need to see distant or hidden connections, or where you need to give
                        license to your creative or expressive abilities. In those situations, your secondary
                        Abstract Relational learning style serves as a back-up mode that allows you to see the
                        silver lining in difficult situations, empathize with others, listen to your conscience,
                        and express your feelings and ideas in new and creative ways.  Go You!
                    </p>
                </div>
            </div>
        );
    }
}

AbstractRelationalSecondaryPartialView.propTypes = {
    score: PropTypes.number
};

export default AbstractRelationalSecondaryPartialView;