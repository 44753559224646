/**
 * Created by Robert on 6/14/19.
 */
import React, {Component} from 'react';
import * as styles from '../../../../styles/assessment.scss';
import PropTypes from 'prop-types';

class AbstractSequentialPrimaryPartialView extends Component {

    render() {
        return (
            <div>
                <div>
                    <h4 className={styles.learningOutcome}>Abstract Sequential (AS): {this.props.score}</h4>
                    <p className={styles.fontSmall1350}>
                        <b>Your dominant mode of information intake is “Abstract.”</b> Dominant Abstract
                        Sequential learners are most comfortable with theoretical, conceptual, or idea-based
                        learning content.  These types of content give them the opportunity to exercise their
                        desire to think on an abstract level and engage with models, ideals, and paradigms.
                        <br/><br/>
                        <b>Your dominant mode of information process is “Sequential.”</b> Dominant Abstract
                        Sequential learners prefer information that is presented clearly, systematically, and in
                        a logical order, and when they process information, they prefer to make crisp, clear
                        categorical distinctions as a means of breaking learning content down into digestible,
                        understandable packets that can be ordered and easily remembered.  Their mental
                        commitment to sequential order is very strong, and so where a sequential structure is not
                        present, they will often go out of their way to create or uncover one.
                        <br/><br/>
                        <b>You have some amazing strengths!</b> Abstract Sequential learners have an amazing
                        ability to see both the forest <i>AND</i> the trees.  In other words, they like to begin
                        their thought process by understanding the bigger picture, but they subsequently
                        understand the big picture by breaking it down into component parts and ordering those
                        parts in a logical sequence.  This is a very powerful learning dynamic, and one with
                        distinct strengths.
                        <br/><br/>
                        To begin with, the Abstract Sequential process of breaking high-level information down
                        into component parts allows them to compare and contrast, analyze and evaluate learning
                        content with ease.  Cause and effect relationships and patterns tend to leap to their
                        attention and then serve as waypoints toward a deeper understanding of the topic at
                        hand.  They have a natural ability to identify both major ideas or themes as well as
                        nuances and implications that underlie big picture items.  All of this translates into
                        the ability to understand the both the “why” and the “how” of a topic expediently and
                        in-depth.
                        <br/><br/>
                        Because of the order in which they take in and process information, Abstract Sequential
                        learners are typically able to explain what they’ve learned with ease by following the
                        exact same path from “forest” to “trees” they followed when they learned about it
                        themselves.  In fact, they get excited about the process of retracing that path and
                        explaining what they’ve learned, and they will build their vocabulary and rhetorical
                        ability to match their communication needs.  Not surprisingly, Abstract Sequentials tend
                        to be (or become) expert communicators, both in the sense that they can draw out the
                        words and language they need and assemble them in such a way that they can best
                        communicate an idea, concept, or theory, and anything that falls out of it.

                        Abstract Sequentials tend to be most engaged by the thought process and can be highly
                        curiosity driven.  They often present themselves as inquisitive, and they are frequently
                        unsatisfied with the first explanations they are given.  This is especially the case when
                        explanations are either watered down or not well structured (from the AS point of view).
                        Consequently, Abstract Sequentials can be especially persistent in questioning “why”
                        things are the way they are; they are sometimes unwilling to let this line of inquiry go
                        until their learning needs are satisfied.  Sometimes this comes off as willful or as
                        belaboring an issue, but in reality Abstract Sequentials often feel as if they haven’t
                        actually learned the content until all their questions have been answered.
                        <br/><br/>
                        <b>But, be mindful of these pitfalls!</b> Just as each learning style has a set of clear
                        strengths, each comes with possible difficulties and pitfalls, too.  Sometimes these
                        weaknesses can be reflections of your strengths.  Regardless, these are things to reflect
                        on and be aware of as you build self-understanding and competency.
                        <br/><br/>
                        Procrastination! Dominant Abstract Sequential learners, perhaps more than other people,
                        are especially prone to procrastination.  Sometimes this can be mistaken for laziness or
                        being disengaged, but the issue runs deeper than that.  Abstract Sequentials prefer to
                        begin with theoretical, conceptual, or idea-based learning content.  In fact, they are
                        often energized by dealing with abstract content, breaking it down, and understanding the
                        “why’s” and “how’s.”  But their enjoyment is derived from the thought process, and most
                        of this excitement evaporates when it is time to transition from thought to practical
                        activity.  Shifting from “thinking” to “doing” is not just a big leap for these learners,
                        it requires mental gymnastics for them to enter into what they feel is a completely new
                        mental activity.  The result is that they often feel suddenly unmotivated, “stopped up,”
                        or even “frozen,” and they can’t or don’t shift into working mode.  Abstract Sequentials
                        set high standards for themselves, and when they hit roadblocks like this, their
                        emotional experience can sometimes drive them into deeper avoidance behavior (thus
                        worsening their situation).  On their own, Abstract Sequentials are prone to put things
                        off until the last minute, when mounting stress will finally force them into action, but
                        this puts them into a dramatically unbalanced work cycle.  Fortunately, they have other
                        options.  Abstract Sequential learners are the masters of lists, and listing is the key
                        to overcoming their mental-emotional obstacles—they just need to learn how to make useful
                        lists by breaking their macro-tasks down into micro-tasks (e.g., tasks that can be
                        completed in 15 minutes).  A good list will give an Abstract Sequential learner a clear
                        sense of what to do and where to start, and with the completion of each micro-task
                        they’ll build the momentum they need to stay active.
                        <br/><br/>
                        Abstract Sequential learners are always in the process of analyzing and evaluating as
                        they try to make sense of the world around them.  As a result, they can be prone to being
                        judgmental.  Many of their judgments are spot on, and so their evaluating-judging process
                        isn’t necessarily the problem.  Rather, the problem is that once Abstract Sequentials
                        make a determination and place it their mental framework, they’re often disinclined to
                        revisit that judgment again.  This disinclination to reevaluate judgments is especially
                        problematic when the judgments pertain to people.  When they make negative judgments of
                        people, they will often write them off as “incompetent” and stop “wasting time” on them.
                        But people do change, and as any teacher can tell you, you can learn from anyone and
                        everyone you meet.  Writing people off limits the range of people that Abstract
                        Sequentials can learn from, and it prevents them from recognizing when people have
                        actually changed.  Interestingly, the problem that Abstract Sequentials face here is part
                        of their learning process rather than an attitude of hypercritical arrogance (though it
                        can come off that way sometimes).  Abstract Sequentials base their understanding on the
                        rational-sequential framework they construct; their evaluations of content (people
                        included) are essential to their ability to understand their world.  For an Abstract
                        Sequential, admitting that their judgement was unfair or mistaken is tantamount to
                        calling their entire learning framework into question.  Now, obviously one misjudgment
                        should NOT call the entire learning framework into question—this is more of an uneasy
                        feeling for Abstract Sequentials than a rational belief.  Fortunately, Abstract
                        Sequentials can overcome this problem by gently recognizing their limitations, slowly
                        accepting that they can benefit from everyone they meet, and adopting a willingness to
                        reevaluate their judgements as a matter of practice.  They are even likely to rationally
                        accept these things if presented the right way.
                        <br/><br/>
                        <b>Be the “You-est You” You Can Be!</b>  In order to be the fullest version of yourself
                        that you can be, you need to deepen your self-awareness, incrementally overcome your
                        challenges, and fully empower yourself to reach your goals.  Here are more things for you
                        to consider in order to help you on your way:</p>
                    <br/>
                    <br/>
                </div>
                <div className={`${styles.columnCount} ${styles.isMultiline} ${styles.typeMatrix}`}>
                    <div className={`${styles.column} ${styles.isHalf} ${styles.backgroundBanana}`}>
                        <h3>Abstract Sequentials don't like:</h3>
                        <hr className={styles.partialHr}/>
                        <p>
                            - vague ideas or insufficient explanations of “why” <br/>
                            - being asked to examine or learn details without some overarching idea to invest them with meaning/purpose<br/>
                            - experiential learning (with no preestablished purpose)<br/>
                            <br/>
                            - interacting with people who can’t grasp the “big picture” or who only see details with no animating idea<br/>
                            - interacting with people who rely on fuzzy, emotional, intuitive ideas without shifting to a rational justification<br/>
                            - group work (unless they’re leading the group)<br/>
                            - being asked to repeatedly justify their ideas and thought processes (or instructions in a group setting)<br/>
                            - being asked to think outside their intake-process mode<br/>
                            - practical applications or watered-down versions of their ideas<br/>
                        </p>
                    </div>
                    <div className={`${styles.column} ${styles.isHalf} ${styles.backgroundLime}`}>
                        <h3>Abstract Sequentials are nurtured by:</h3>
                        <hr className={styles.partialHr}/>
                        <p>
                            - being encouraged to think in their natural intake-process dynamic<br/>
                            - learning spaces that accommodate their style; private spaces/projects that still afford the ability to be social<br/>
                            - learning environments that combine high expectations, ample resources, and freedom to self-direct learning<br/>
                            - sequential learning materials—outlines, books, lectures<br/>
                            <br/>
                            - being surrounded by people who value rational thinking, dialogue, and ambitious intellectual goals<br/>
                            - working with mentors who are masters of their fields and who actively foster others’ intellectual pursuits<br/>
                            - teachers who maintain (and can demonstrate) their intellectual ability and pedagogical authority<br/>
                            - recognition (esp. verbal) from respected teachers and mentors for work and accomplishments
                        </p>
                    </div>
                    <div className={`${styles.column} ${styles.isHalf} ${styles.backgroundApple}`}>
                        <h3>Abstract Sequentials worry about:</h3>
                        <hr className={styles.partialHr}/>
                        <p>
                            - mastering subjects of interest (esp. under a deadline)<br/>
                            - living up to self-expectations; living up to the expectations of respected mentors, teachers, etc.<br/>
                            - being thought of as stupid, weak, or incompetent because of mistakes or misjudgments they’ve made<br/>
                            - making decisions without enough information<br/>
                            - committing to ideas, positions, relationships<br/>
                            - losing their freedom to contemplate or explore ideas<br/>
                            - being able to explain/justify their ideas to instructors, mentors, or peers<br/>
                            - being viewed as “know-it-all”— being written off, alienated, or ostracized
                        </p>
                    </div>
                    <div className={`${styles.column} ${styles.isHalf} ${styles.backgroundBlueberry}`}>
                        <h3>Abstract Sequentials develop when:</h3>
                        <hr className={styles.partialHr}/>
                        <p>
                            - they learn to overcome their pitfalls and challenges<br/>
                            - they are challenged to think “outside of the box” or in a learning style that is atypical for them<br/>
                            - they recognize that other learning modes supports their learning process by enhancing their perspective<br/>
                            - they practice humility (as a means of being open to new information and perspectives, and as a means of rolling back their tendency to be rigid)<br/>
                            - they recognize that mistakes do NOT equal weaknesses<br/>
                            - they recognize their limitations and practice self-acceptance<br/>
                            - they are willing to ask for and openly receive help<br/>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

AbstractSequentialPrimaryPartialView.propTypes = {
    score: PropTypes.number
};

export default AbstractSequentialPrimaryPartialView;