import LazyImage from '../components/lazyimage';
import React, { Component } from 'react';
import 'whatwg-fetch';
import 'babel-polyfill';
import { FormHandler } from '../components/form-handler';
import * as styles from '../styles/assessment.scss';
import PropTypes from 'prop-types';

class AssessmentSubmissionForm extends Component {
    render() {
        return (<section className={`${styles.section} ${styles.fullHeight} ${styles.vCentered}`}>
            {/* A honeypot to catch the bots */}
            <div style={{zIndex: '-1000', position: 'absolute'}}>
                <input name="terms-and-conditions" id="terms-and-conditions" type="checkbox"/>
                <label htmlFor="terms-and-conditions"> I agree to the terms and conditions
                    <a href="https://www.highpoint.education/pages/terms" target="_blank"
                       rel="noopener noreferrer">
                        (Terms)
                    </a></label>
            </div>
            {/* End honeypot */}
            <div className={`${styles.checkOverlayWrapper} ${styles.scaleWrapper} ${styles.scaleOut}`}
                 id="check-overlay-wrapper">
                <div className={styles.checkOverlay}>
                    <i className='fas fa-check'>
                    </i>
                </div>
            </div>
            <LazyImage
                id="assessment-bg"
                className={`${styles.backgroundContainer} 
                ${styles.fadeWrapper}`}
                bg={true}
                srcPreload={this.props.preloadBg}
                srcLoaded={this.props.fullBg}
            />
            <div className={styles.lazyBackgroundContent}>
                <div className={`${styles.columnCount} ${styles.isMultiline}`} id="submit-text">
                    <div className={`${styles.column} ${styles.isFull}`} style={{textAlign: 'center'}}>
                        <h1 style={{color: 'whitesmoke'}}>You're almost there!</h1>
                    </div>
                    <div className={`${styles.column} ${styles.isFull}`} style={{textAlign: 'center'}}>
                        <h2 style={{color: 'whitesmoke'}}>Enter your name and email address and view your
                            results.</h2>
                    </div>
                </div>
                <div className={styles.vCentered}>
                    <article id="assessment-form"
                             className={`${styles.tile} ${styles.isChild} ${styles.notification} ${styles.whiteTile} ${styles.welcomeTile} ${styles.widePadding} ${styles.fadeWrapper}`}>
                        <div className={`${styles.spinnerWrapper} ${styles.fadeWrapper} ${styles.fadeOut}`}
                             id="spinner-wrapper">
                            <div className={styles.spinnerChild}>
                                <i className='fas fa-spinner'/>
                            </div>
                        </div>
                        <div className={`${styles.field} ${styles.isHorizontal}`}>
                            <div className={`${styles.fieldLabel} ${styles.isNormal}`}>
                                <label className={styles.label}>Full Name</label>
                            </div>
                            <div className={styles.fieldBody}>
                                <div className={styles.field}>
                                    <p className={`${styles.control} ${styles.isExpanded}`}>
                                        <input id="first-name-field" className={styles.input} type="text"
                                               placeholder="First Name"/>
                                    </p>
                                </div>
                                <div className={styles.field}>
                                    <p className={`${styles.control} ${styles.isExpanded}`}>
                                        <input id="last-name-field" className={styles.input} type="text"
                                               placeholder="Last Name"/>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className={`${styles.field} ${styles.isHorizontal}`}>
                            <div className={`${styles.fieldLabel} ${styles.isNormal}`}>
                                <label className={styles.label}>Email</label>
                            </div>
                            <div className={styles.fieldBody}>
                                <div className={`${styles.field} ${styles.isExpanded}`}>
                                    <div className={`${styles.field} ${styles.hasAddons}`}>
                                        <p className={`${styles.control} ${styles.isExpanded} ${styles.hasIconsLeft}`}>
                                            <input id="email-field" className={styles.input} type="email"
                                                   placeholder="Email"/>
                                            <span className={`${styles.icon} ${styles.isSmall} ${styles.isLeft}`}>
                                                <i className='fas fa-envelope'/>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.field} ${styles.isHorizontal}`}>
                            <div className={`${styles.fieldLabel} ${styles.isNormal}`}>
                                <label className={styles.label}>I am a...</label>
                            </div>
                            <div className={styles.fieldBody}>
                                <div className={`${styles.field} ${styles.isExpanded}`}>
                                    <div className={styles.select}>
                                        <select style={{minWidth: '100%'}} id="type-field">
                                            <option value="none">Click to make a selection</option>
                                            <option value="student">Student</option>
                                            <option value="parent">Parent</option>
                                            <option value="advisor">Advisor/Counselor</option>
                                            <option value="professional">Professional</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.field} ${styles.isHorizontal}`}>
                            <div className={styles.fieldLabel}>
                            </div>
                            <div className={styles.fieldBody}>
                                <div className={`${styles.field} ${styles.isGrouped}`}>
                                    <div className={styles.control}>
                                        <button
                                            className={`${styles.button} ${styles.isPrimary}`}
                                            id="submit-button"
                                            onClick={this.props.submitAnswersNoOverwrite}>View Results
                                        </button>
                                    </div>
                                    <div className={styles.control} style={{flexShrink: 'unset'}}>
                                        <label className={styles.checkbox}
                                               style={{marginTop: '7px'}}>
                                            <input id="tos-checkbox" type="checkbox"/>
                                            &nbsp; &nbsp;I agree to the&nbsp;
                                            <a href="https://www.highpoint.education/pages/terms" target="_blank"
                                               rel="noopener noreferrer">
                                                terms and conditions
                                            </a>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
            <div id="message-modal" className={styles.modal}>
                <div className={styles.modalBackground} onClick={FormHandler['closeModal']}/>
                <div className={styles.modalCard}>
                    <header className={styles.modalCardHead}>
                        <p id="message-modal-title" className={styles.modalCardTitle}>Unknown</p>
                        <button className={styles.delete} onClick={FormHandler['closeModal']}/>
                    </header>
                    <section className={styles.modalCardBody}>
                        <div className={styles.content}>
                            <p id="message-modal-content">Unknown</p>
                        </div>
                    </section>
                    <footer className={styles.modalCardFoot}>
                        <a className={styles.button} onClick={FormHandler['closeModal']}>Close</a>
                        <a id="confirm-button" className={`${styles.button} ${styles.isDanger}`}
                           style={{display: 'none'}}
                           onClick={this.props.submitAnswersOverwrite}>Confirm</a>
                    </footer>
                </div>
            </div>
        </section>);
    }
}

AssessmentSubmissionForm.propTypes = {
    submitAnswersOverwrite: PropTypes.func,
    submitAnswersNoOverwrite: PropTypes.func,
    fullBg: PropTypes.string,
    preloadBg: PropTypes.string
};

export default AssessmentSubmissionForm;