/**
 * Created by Robert on 6/14/19.
 */
import React, {Component} from 'react';
import * as styles from '../../../../styles/assessment.scss';
import PropTypes from 'prop-types';

class ConcreteSequentialPrimaryPartialView extends Component {

    render() {
        return (
            <div>
                <div>
                    <h4 className={styles.learningOutcome}>Concrete Sequential (CS): {this.props.score}</h4>
                    <p className={styles.fontSmall1350}>
                        <b>Your dominant mode of information intake is “Concrete.”</b> Dominant Concrete
                        Sequential learners prefer well-established, clearly defined learning content as opposed
                        to subjective, abstract, or theoretical content.  They are drawn to facts, examples,
                        objects, and other types of learning content that can be understood clearly regardless of
                        context or perspective.
                        <br/><br/>
                        <b>Your dominant mode of information process is “Sequential.”</b> Dominant Concrete
                        Sequential learners prefer information that is presented clearly, systematically, and in
                        a logical order.  Likewise, when they process information, they prefer to make crisp,
                        clear categorical distinctions as a means of breaking learning content down into
                        digestible, understandable packets that can be ordered and easily remembered.  For this
                        reason, factual detail and rational order are extremely important to their learning
                        process.
                        <br/><br/>
                        <b>You have some amazing strengths!</b> Concrete Sequential learners have an uncanny
                        ability to remember facts and details.  In fact, their memory of certain things can
                        border on photographic.  They are also very good at making distinctions by tapping into
                        their ability to identify categories of information.  As a result, they are often able to
                        pick up on finer degrees of detail, minute differences, and subtle nuances in their
                        environment that others might not.  Concrete Sequential learners are very discriminating,
                        and in some situations, this ability can serve them as a sort of learning or
                        environmental intuition.
                        <br/><br/>
                        Concrete Sequential learners are able to organize their materials and workspace, and
                        structure their time with ease.  They are competent, self-reliant, hard-working, and
                        consistent.  Because they work comfortably at the level of detail, and because they tend
                        to break tasks down to that same level of detail, they are able to shift from learning
                        and analysis to the execution of tasks with ease.  In other words, for Concrete
                        Sequential learners, there is no gap between thinking and doing, and they are typically
                        able to begin working on tasks and projects without any difficulty.  They are not prone
                        to procrastination, and they don’t need a “big picture” view of things to tap into their
                        motivation, and as a result, they are willing to put off gratification until the whole
                        task or project is complete.  Concrete Sequential learners understand how individual
                        tasks and different stages are ordered to an end goal, and they work through these one at
                        a time.  They tend to be methodical in the way they complete their work, and their
                        motivation can appear natural.
                        <br/><br/>
                        <b>But, be mindful of these pitfalls!</b> Just as each learning style has a set of clear
                        strengths, each comes with possible difficulties and pitfalls, too.  Sometimes these
                        weaknesses can be reflections of your strengths.  Regardless, these are things to reflect
                        on and be aware of as you build self-understanding and competency.

                        Dominant Concrete Sequential learners, perhaps more than other learners, expect learning
                        content to be communicated to them in a way that matches their intake-process dynamic;
                        when the information presented is subjective, abstract, or over emotional, or when the
                        information presented is out of sequence, they can find the learning experience very
                        frustrating.  For a Concrete Sequential learner to get past this is not a simple matter
                        of adjusting; when information presented is incongruent with their learning style, they
                        are literally robbed of either a starting point for learning or a rational system to
                        process.  If they aren’t able to dip into a secondary mode of learning, then they will
                        need to translate what is being said or taught into something that they can mentally
                        digest.  This takes awareness and practice.
                        <br/><br/>
                        Concrete Sequential learners also have high expectations of themselves.  They are precise
                        about what constitutes success and excellence, they are methodical about the way they
                        progress through their work and toward their goals, they are often uncompromising on
                        certain things, and they are frequently too hard on themselves.  All of these tendencies
                        can be the backbone of determination and grit, but they can also become obstacles to
                        success and create or exacerbate stress.  To put this differently, Concrete Sequential
                        learners have a habit of “letting the perfect be the enemy of the good.”  They can get
                        hung up on things being perfect and everything going according to plan, and when this
                        doesn’t happen, their stress levels shoot up, and they can end up feeling stuck.  Their
                        reaction may be to double-down and work harder, and even if they succeed, they may feel
                        mentally and emotionally exhausted by the end.  Concrete Sequentials benefit when they
                        practice letting go—this can be difficult at first, but the gains are felt over time.
                        <br/><br/>
                        Because Concrete Sequential learners have an uncanny knack for remembering facts and
                        details, they have a tendency to carry a tremendous amount of working information around
                        in their heads.  Another person might make a lengthy list of to-do’s (they’d have to!),
                        but Concrete Sequentials can carry all these things in memory without forgetting
                        anything.  Unfortunately, because it is so easy for them to remember their to-do’s, they
                        frequently forget that it actually does require a fair amount of mental energy to do this
                        on an ongoing basis.  Sometimes the process of carrying so many things in memory can lead
                        to increased stress; that mental energy spent on remembering everything could be better
                        spent on accomplishing tasks if they would take moment to write a list.  Surprisingly,
                        when Concrete Sequentials write lists, they rarely need to look at the lists to remember
                        what they need to do—the real benefit of this activity comes from knowing everything is
                        written down (they don’t need to worry about forgetting something, their stress is
                        relieved, and they get that mental energy back).
                        <br/><br/>
                        Concrete Sequential learners are deeply emotional people, but they tend to process their
                        emotions privately and slowly.  And because they have the ability to be so focused on
                        their work, they sometimes neglect their emotional selves.  Other people might mistake
                        this for everything being fine, and in fact, “I’m fine” is exactly what you might hear
                        from a Concrete Sequential learner if you ask how they’re doing emotionally.  But
                        emotions can build up when they’re not attended to, and for Concrete Sequential learners,
                        “I’m fine” doesn’t always mean that they are feeling emotionally happy or strong.
                        Because they tend to process their emotions privately and slowly and because they can
                        neglect their feelings, Concrete Sequential learners sometimes find that their emotions
                        reach a boiling point if they’ve failed to give them the attention they deserve.  And
                        when this happens, they can feel embarrassed about it and be overly hard on themselves
                        for being “over-emotional.”  In reality, they are experiencing something very normal, and
                        their emotional response is truly ok.  Practicing mindfulness is helpful here, provided
                        it is done practically (and without becoming “wishy-washy” or “touchy-feely”).  Concrete
                        Sequentials do well when they remember to pay attention to how they feel, and they
                        benefit from giving themselves emotional outlets (e.g., quiet time, physical exercise,
                        etc.) that help them process their feelings.
                        <br/><br/>
                        <b>Be the “You-est You” You Can Be!</b>  In order to be the fullest version of yourself
                        that you can be, you need to deepen your self-awareness, incrementally overcome your
                        challenges, and fully empower yourself to reach your goals.  Here are more things for you
                        to consider in order to help you on your way:</p>
                    <br/>
                    <br/>
                </div>
                <div className={`${styles.columnCount} ${styles.isMultiline} ${styles.typeMatrix}`}>
                    <div className={`${styles.column} ${styles.isHalf} ${styles.backgroundBanana}`}>
                        <h3>Concrete Sequentials don't like:</h3>
                        <hr className={styles.partialHr}/>
                        <p>
                            - unclear, inconsistent, or unrealistic expectations<br/>
                            - working in a dirty or disorderly environment<br/>
                            - waste (e.g., time, energy, money, etc.)<br/>
                            - surprises, scheduling errors, last-minute changes<br/>
                            - situations that feel chaotic or out of control<br/>
                            - being presented with too many options to choose from<br/>
                            <br/>
                            - carelessness, forgetfulness, tardiness<br/>
                            - working with people who do not do their part or take their responsibilities seriously<br/>
                            - being taken for granted, taken advantage of<br/>
                            - being associated with mediocre work<br/>
                            <br/>
                            - unnecessary social drama; pettiness, disloyalty<br/>
                            - discussing abstract, subjective, or emotional topics<br/>
                            - dealing with or choosing between opposing views or gray areas; basing choices on subjective opinion<br/>
                            - being asked to focus on the “forest” rather than the “trees”
                        </p>
                    </div>
                    <div className={`${styles.column} ${styles.isHalf} ${styles.backgroundLime}`}>
                        <h3>Concrete Sequentials are nurtured by:</h3>
                        <hr className={styles.partialHr}/>
                        <p>
                            - clean, orderly environments; limited distractions<br/>
                            - consistent schedules, dependable routines<br/>
                            - clear, step-by-step directions<br/>
                            - concrete examples, experiential learning activities<br/>
                            - lecture notes or slides, worksheets, writing prompts, manuals, instruction sheets, review sessions<br/>
                            - clear objectives; fair, consistent and objective expectations; rewards based on actual performance<br/>
                            <br/>
                            - faculty/instructors who establish and preserve conventional student-teacher dynamics<br/>
                            - relationships with clear, direct, organized, and competent peers, colleagues, etc.<br/>
                            - social groups built on norms of fairness, loyalty, hard work, respect for rules, recognition of accomplishments<br/>
                            - direct, honest, and objective feedback on their work<br/>
                            - acceptance and respect from friends and colleagues<br/>
                            - recognition for hard work and/or accomplishments
                        </p>
                    </div>
                    <div className={`${styles.column} ${styles.isHalf} ${styles.backgroundApple}`}>
                        <h3>Concrete Sequentials worry about:</h3>
                        <hr className={styles.partialHr}/>
                        <p>
                            - trying to be perfect or right all the time; trying to maintain their status, rank<br/>
                            - risks, unknown factors, major changes, and their ability to adapt and be successful<br/>
                            - being presented with too many options and being able to make the “right” decision<br/>
                            - missing or misunderstanding what is being communicated (e.g., inferences, subtleties, etc.)<br/>
                            - not being fully prepared for projects, presentations, tests, examinations, etc.
                        </p>
                    </div>
                    <div className={`${styles.column} ${styles.isHalf} ${styles.backgroundBlueberry}`}>
                        <h3>Concrete Sequentials develop when:</h3>
                        <hr className={styles.partialHr}/>
                        <p>
                            - they practice letting go of things that are imperfect or outside of their control<br/>
                            - they are reminded not to be too hard on themselves; they stop letting “the perfect be the enemy of the good”<br/>
                            - they have contact with people who assist them with goal-setting and prioritization<br/>
                            - they are gently encouraged to step outside of their comfort zone and/or think “outside of the box”<br/>
                            - they are challenged to see the world in shades of gray rather than in black and white<br/>
                            - they acknowledge their emotions/see them as valid
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

ConcreteSequentialPrimaryPartialView.propTypes = {
    score: PropTypes.number
};

export default ConcreteSequentialPrimaryPartialView;