/**
 * Created by Robert on 6/25/19.
 */
import React, { Component } from 'react';
import '../../../styles/animate.css';
import preloadBg from '../../../images/college-readiness-test/submit-bg-blur.jpg';
import fullBg from '../../../images/college-readiness-test/submit-bg.jpg';
import config from '../../../config/assessment/crt/questions';
import 'whatwg-fetch';
import 'babel-polyfill';
import { FormHandler } from '../../../components/form-handler';
import AssessmentSubmissionForm from '../../assessment-submission-form.view';
import InputValidationUtil from '../../../../common/utility/input-validation.util';
import * as styles from '../../../styles/assessment.scss';

class CollegeReadinessTestSubmitView extends Component {
    render() {
        return (
            <AssessmentSubmissionForm
                preloadBg={preloadBg}
                fullBg={fullBg}
                submitAnswersOverwrite={submitAnswersOverwrite}
                submitAnswersNoOverwrite={submitAnswersNoOverwrite}>
            </AssessmentSubmissionForm>
        );

        function submitAnswers(overwrite) {
            FormHandler.closeModal();
            let jsonData = {
                'type': 'crt',
                'assessment': {
                    'answers': {
                        'categories': {}
                    }
                },
                'user': {
                    'first': document.getElementById('first-name-field').value,
                    'last': document.getElementById('last-name-field').value,
                    'email': document.getElementById('email-field').value,
                    'type': document.getElementById('type-field').value,
                    'do overwrite': overwrite
                }
            };

            for (let k = 0; k < config.categories.length; k++) {
                const elementIdentifier = 'question-' + (k + 1);
                const element = document.getElementById(elementIdentifier);
                const category = element.dataset.category;
                jsonData.assessment.answers.categories[category] = FormHandler.getAnswerLevelFromInputs(document.getElementsByName(elementIdentifier));
            }

            if (!FormHandler.validateAnswers(jsonData.assessment.answers.categories, InputValidationUtil.validateAnswersCrt)) {
                return;
            }

            document.getElementById('assessment-form').classList.add(styles.sendingFilter);
            document.getElementById('spinner-wrapper').classList.remove(styles.fadeOut);

            FormHandler.postResults(jsonData);
        }

        function submitAnswersOverwrite() {
            submitAnswers(true);
        }

        function submitAnswersNoOverwrite() {
            submitAnswers(false);
        }
    }
}

export default CollegeReadinessTestSubmitView;