import React, { Component } from 'react';
import * as styles from '../../../styles/assessment.scss';

class CrtResultsContentPartialView extends Component {

    render() {
        return (
            <section className={`${styles.section} ${styles.fullHeight} ${styles.primaryBackground} ${styles.pageMargin}`}>
                <div className={`${styles.columnCount} ${styles.isMultiline} ${styles.widePadding}`} style={{paddingTop: 0}}>
                    <div className={`${styles.column} ${styles.isFull}`}>
                        <h2 style={{textAlign: 'center'}}>
                            How to Read Your Scores
                        </h2>
                        <div className={styles.fontSmall1350}>
                            <p>
                                This skills assessment is designed to give you an idea of where your academic, self-efficacy, and pre-professional
                                skills stand at present in the context of college-level education. This context allows you to compare your present
                                skillset to what you will need to be successful in a college or university environment. While it showcases your
                                core strengths, it also helps you identify areas in need of attention and specific skills that may be lagging behind
                                your baseline of proficiency.
                                <br/><br/>
                                Your score should be read in a few different ways. First, let’s look at the aggregate (overall) score.</p>
                        </div>
                        <div className={`${styles.column} ${styles.isFull}`}>
                            <h3 className={styles.vCentered}>
                                <span id="aggregate-score" className={styles.crtScore}>{this.score}</span>&nbsp;Aggregate Score
                            </h3>
                        </div>
                        <div className={`${styles.fontSmall1350}`}>
                            <p>
                                If you consider your aggregate score in the context of the score ranges below, this number can help you to gauge
                                your overarching skill level and give you a sense of your general level of preparedness for college.
                                <br/><br/>
                                <b>25-59: Severely Underprepared</b>—the majority of basic skills acquired are critically underdeveloped
                                <br/>
                                <b>60-79: Underprepared</b>—basic skills have been acquired, but are mostly underdeveloped
                                <br/>
                                <b>80-99: Moderately Prepared</b>—foundation of basic skills, but lacking in key areas
                                <br/>
                                <b>100-125: Prepared</b>—foundational skills appropriate to an entering college student
                                <br/><br/>
                            </p>
                        </div>
                        <div className={`${styles.fontSmall1350}`}>
                            <h2 style={{textAlign: 'center'}}>
                                Your Score Range
                            </h2>
                            <div id='score-range'>
                            </div>
                            <br/>
                        </div>
                    </div>
                    <div className={`${styles.fontSmall1350}`}>
                        <h2 style={{textAlign: 'center'}}>
                            Skills Families & Individual Skills
                        </h2>
                        <p>
                            Now let's look more closely at skills families and individual skills to get a more detailed view of your skillset.
                            In the bar chart below you will see each of the 25 individual skills examined broken down into one of three skills
                            families: "academic," "personal," or "pre-professional." This view of your individual skills will allow you to see
                            how each one compares to the next, and this may draw your attention to a particular skill that has remained
                            underdeveloped despite your strengths in other areas. At the same time, the view of skills families can help reveal
                            common factors behind certain underdeveloped skills, especially if one family of skills appears to lag behind the
                            others. Once you've considered your skills families, take a close look at your individual skills to get a sense of
                            which ones are especially strong, and which ones need attention.
                        </p>
                    </div>
                    <div className={`${styles.vCentered} ${styles.crtBarContainer}`} id={'results-bar'} style={{marginTop: '16px'}}>
                        <div id="academic-column">
                        </div>
                        <div id="personal-column">
                        </div>
                        <div id="pre-professional-column">
                        </div>
                    </div>
                    <div className={`${styles.column} ${styles.isFull}`}>
                        <h2 style={{textAlign: 'center'}}>
                            Strengths and Weaknesses
                        </h2>
                        <p>
                            No matter what your score, remember that it is NOT an indication of your intellectual ability or your potential
                            to reach your goals; it simply identifies your “skills starting point” so that you can reach “your skills
                            highpoint.” Don’t let your score discourage you and don’t get lazy; instead, use our recommendations to take
                            your skills to the next level.
                            <br/><br/>
                            Below you will find your strongest and weakest skills areas along with a brief description of what that skill
                            entails. Your strongest skills are likely to be your "anchor skills," or those skills that you can depend on
                            when challenged. Your weakest skills are the ones that are likely to be inadequate when demanded of you, or
                            where you are more likely to experience deficiencies as educational demands escalate. These are your skills
                            in immediate need of attention.
                        </p></div>
                    <div className={`${styles.column} ${styles.isFull}`}>
                        <h2 style={{textAlign: 'center'}}>
                            Strongest Skills
                        </h2>
                        <div id='strongest-skill-areas'>
                        </div>
                        <div style={{marginTop: '20px'}}/>
                        <h2 style={{textAlign: 'center'}}>
                            Weakest Skills
                        </h2>
                        <div id='weakest-skill-areas'>
                        </div>
                    </div>
                    <div className={`${styles.column} ${styles.isFull}`}>
                        <h2 style={{textAlign: 'center'}}>
                            What Can You Do With This Information?
                        </h2>
                        <p>
                            You should now have a better sense of your overall skill level, your preparedness for college, and where to focus your
                            time and energy as you develop specific skills. Regardless of what your aggregate score is, there are skills and skills
                            areas that you should continue to develop as you move closer to your college- or university-level education. Your next
                            step is to take the assessment information provided here and begin to build on your present skills. This can be
                            challenging, and so you can start by identifying tools and resources that can incrementally raise your ability level in
                            key areas. It’s in your best interest to be proactive about developing your skills, but you can’t do all the work at
                            once. Once you’ve identified appropriate tools and resources, build a plan to elevate your skills over time, scale your
                            expectations, look for results, and course-correct as needed.
                            <br/><br/>
                            We’re here to help you with this process! Our Master Courses, the Empowered Student and the Apex Student, can help you
                            make your way through the skills-building process, taking you beyond obvious lessons and into a new experience of your
                            academic and personal potential. Learn more about our courses by clicking <a href="https://www.highpoint.education/collections">HERE</a>.
                            <br/><br/>
                            If you’re still struggling to get an idea of your skill level after seeing these results, and your not sure if the master
                            courses are for you, consider setting up a consultation with one of our specialists. Our staff of highly-trained success
                            coaches can help you understand the results of your assessment, strategize around your strengths and challenges, and
                            direct you to tools and resources that will help you build a foundation of college-ready skills. You can learn more about
                            our success coaching packages by clicking <a href="https://www.highpoint.education/pages/coaching">HERE</a>.
                        </p>
                    </div>
                </div>
            </section>
        );
    }
}

export default CrtResultsContentPartialView;