import React, { Component } from 'react';
import * as styles from '../../../../styles/assessment.scss';

class CrtUnderpreparedPartialView extends Component {

    render() {
        return (
            <React.Fragment>
                <h3 className={styles.crtUnderprepared}>60-79 Underprepared</h3>
                <p>
                    An aggregate score between 60 and 79 indicates a concerning level of under-preparedness for college- or university-
                    level education. A score in this range could be the result of an underdeveloped foundation of skills, or it could be
                    the result of multiple areas of critical weakness. In either case, students with scores in this range will find
                    themselves with an inadequate set of academic, personal, and/or pre-professional skills to meet their educational
                    demands. Initial skills deficiencies of this kind may prevent students from acclimating to their educational
                    environment; as educational demands escalate, continued skills deficiencies may jeopardize students’ abilities to
                    remain in college. To put this in perspective, ongoing skills deficiencies can result in lower academic performance;
                    over 60% of students with a GPA of less than 3.0 in their first year of studies will not graduate within 6 years of
                    entering college.
                    <br/><br/>
                    An inadequate foundation of skills is in no way an indication of a student’s intellectual ability, but if deficiencies
                    are not addressed and corrected, they may negatively impact a person’s access to educational and career opportunities.
                    Whether you are preparing for college or already enrolled, our recommendation is to immediately and systematically
                    address your skills deficiencies. Our Master Courses and Success Coaching options provide a
                    comprehensive approach to address any and all areas of concern. Learn more <a href="https://www.highpoint.education/collections">HERE</a>.
                    <br/><br/>
                    If you have concerns about your score, about our products, or about the timeframe wherein you need to see results,
                    contact us directly at info@highpoint.education to learn more about how we can meet your needs.
                </p>
            </React.Fragment>
        );
    }
}

export default CrtUnderpreparedPartialView;