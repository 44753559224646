/**
 * Created by Robert on 6/14/19.
 */
import React, {Component} from 'react';
import * as styles from '../../../../styles/assessment.scss';
import PropTypes from 'prop-types';

class ConcreteRelationalPrimaryPartialView extends Component {

    render() {
        return (
            <div>
                <div>
                    <h4 className={styles.learningOutcome}>Concrete Relational (CR): {this.props.score}</h4>
                    <p className={styles.fontSmall1350}>
                        <b>Your dominant mode of information intake is “Concrete.”</b> Dominant Concrete
                        Relational learners prefer well-established, clearly defined learning content that can
                        serve as the starting point for their learning process.  While they are drawn to facts,
                        examples, objects, and other types of learning content that can be understood regardless
                        of context or perspective, they are also able to make a concrete starting points out of
                        specific theories, abstract ideas, or subjective opinions.
                        <br/><br/>
                        <b>Your dominant mode of information process is “Relational.”</b> Dominant Concrete
                        Relational learners prefer information that is presented in a relational context showing
                        the interconnections between the primary learning content and facts, theories, objects,
                        examples, concepts, etc. that help to co-define or illuminate it.  Concrete Relational
                        learners place new information in context, but they also trace different pathways through
                        that contextual framework as a means of understanding different elements of the learning
                        content and the “big picture” surrounding it.
                        <br/><br/>
                        <b>You have some amazing strengths!</b> Concrete Relational learners have an uncanny
                        ability to see into complex, confusing, and difficult topics and situations.  Their
                        intake-process dynamic moves from the learning object to its context and then back again,
                        opening up many different perspectives and affording them the opportunity to see
                        contextual subtleties that someone else might overlook or miss altogether.  As a result,
                        Concrete Relational learners are able to uncover root causes, main ideas, guiding
                        principles, underlying motives, and potential conflicts with relative ease.  In some
                        cases, they do this in such a fluid manner that they may not recognize how their learning
                        process is operative in all this—instead they are likely to feel as if their grasp on the
                        topic or situation is more intuitive than rational.  In reality their process is both
                        rational and intuitive and when asked they are quite able to give a thoroughgoing account
                        of what they see and understand (even if they sometimes find it tedious to explain their
                        thought process).
                        <br/><br/>
                        Concrete Relational learners have an immense talent for brainstorming and creative
                        problem solving.  In fact, it is not uncommon for Concrete Relational learners to enjoy
                        the process of identifying possible solutions more than the process of implementing one
                        of them!  When they are in this mode of thinking, they tap into the ability to see the
                        central object or theme from many different angles.  They have no difficulty conforming
                        to rules, parameters, or restrictions—these are all part of the challenge of the game!
                        They will then quickly set to work identifying and building possible solutions to the
                        problem ranging from the conventional to the most “left-field” options imaginable.

                        Concrete Relational learners are typically able to take on new challenges without being
                        intimidated or afraid of making mistakes along the way.  Because so much of their
                        learning process is based on moving back and forth between content and context, using
                        trial and error to explore new topics and ideas, they don’t see mistakes as fundamental
                        weaknesses, but rather as steps to their in-depth learning.  Not surprisingly, they tend
                        to have a fairly high tolerance for calculated risks and they suffer mistakes and
                        missteps better than many people—which is to say openly and in good humor.  Concrete
                        Relational learners are typically comfortable with themselves and enjoy their
                        independence, and while they’re sensitive to other people’s thoughts and feelings, they
                        tend not to let themselves be defined or deterred by others.  All of this comes off as
                        highly charismatic, and so Concrete Relational learners can be extremely powerful
                        leaders—they have the charisma, but behind that they have the ability to inspire others,
                        promote critical thinking and creative problem solving, discover unconventional
                        techniques or methods, and strive for amazing results.
                        <br/><br/>
                        <b>But, be mindful of these pitfalls!</b> Just as each learning style has a set of clear
                        strengths, each comes with possible difficulties and pitfalls, too.  Sometimes these
                        weaknesses can be reflections of your strengths.  Regardless, these are things to reflect
                        on and be aware of as you build self-understanding and competency.
                        <br/><br/>
                        Dominant Concrete Relational learners get excited about projects, problems and
                        solutions.  This means that they can tap into a huge store of intellectual energy when
                        they get inspired—they’ll work tirelessly to understand every last possible angle on a
                        topic, they’ll stay up to all hours of the night to learn about some obscure and
                        interesting detail, and they’ll even give 110% to find the best possible solution to a
                        problem.  But, it can be very easy for them to get lost in all of this excitement and
                        dedicate too much of themselves (and their time) to the topic, project, or issue that has
                        caught their attention.  The result is that Concrete Relational learners sometimes have
                        the tendency to work on one thing at the expense of all others.  They lose sight of the
                        fact that they need to balance multiple priorities and they occasionally race down the
                        proverbial “rabbit hole” (with glee).  Fortunately, with a bit of practice, there are
                        several time management techniques that help Concrete Relationals learn to distribute
                        their time and energy appropriately across multiple responsibilities.  Once these are in
                        place, it’s a matter of tempering their impulses.
                        <br/><br/>
                        Concrete Relational learners can struggle with structures, rules, and restrictions that
                        they feel limit their freedom or ability to engage with topics of interest.  They have no
                        problem with rules, parameters, or restrictions that are practically related to a task or
                        project (e.g., “design a widget, but make sure it can fit in this box, that it is only
                        2lbs., and that it can be powered by a 9v battery”), but rules that hinder their ability
                        to engage are another story (e.g., “you must be at your desk from 9-5pm” or “you must
                        follow these steps to complete this task”).  This second kind of limitation usually
                        appears meaningless and monotonous to Concrete Relational learners, and they can
                        eventually come to resent them if they stand in the way of their ability to do their best
                        work.  This is not some kind of antiauthoritarianism, but rather a feature related to
                        their intake-process dynamic.  The freedom to move, whether intellectually or physically,
                        facilitates the Concrete Relational ability to see the central object or theme from many
                        different angles.  Removing that freedom prevents Concrete Relational learner from doing
                        what they do best.  A hard truth for Concrete Relationals to learn is that the world is
                        full of meaningless restrictions and monotonous working environments.  While learning
                        this life-lesson may be developmental at some point, it is far more important for
                        Concrete Relational learners to seek out environments that will challenge them to work
                        hard while promoting their freedom, curiosity, and inventiveness.
                        <br/><br/>
                        <b>Be the “You-est You” You Can Be!</b>  In order to be the fullest version of yourself
                        that you can be, you need to deepen your self-awareness, incrementally overcome your
                        challenges, and fully empower yourself to reach your goals.  Here are more things for you
                        to consider in order to help you on your way:</p>
                    <br/>
                    <br/>
                </div>
                <div className={`${styles.columnCount} ${styles.isMultiline} ${styles.typeMatrix}`}>
                    <div className={`${styles.column} ${styles.isHalf} ${styles.backgroundBanana}`}>
                        <h3>Concrete Relationals don't like:</h3>
                        <hr className={styles.partialHr}/>
                        <p>
                            - meaningless rules and policies; monotonous routines, environments, processes, etc.<br/>
                            - overly detailed instructions, policies, guidelines <br/>
                            - rote work; projects that lack vision<br/>
                            <br/>
                            - working with people who are incompetent or unethical<br/>
                            - being supervised/criticized by people they believe are incompetent, unethical, and/or hypocritical<br/>
                            - having their creativity, ideas, inspiration belittled<br/>
                            - repeated demands to explain their thought process or how they come up with their solution(s)<br/>
                            - interacting with people who lack vision or who are unwilling/unable to think “outside the box”<br/>
                            - coming in second, losing to other people
                        </p>
                    </div>
                    <div className={`${styles.column} ${styles.isHalf} ${styles.backgroundLime}`}>
                        <h3>Concrete Relationals are nurtured by:</h3>
                        <hr className={styles.partialHr}/>
                        <p>
                            - being given the freedom to explore topics, issues, etc.<br/>
                            - being challenged to look for deeper meanings, novel techniques, better solutions<br/>
                            - being allowed to use their intuition as a valid part of the thinking/learning process<br/>
                            - a combination of examples and abstract ideas that can provide the fullest view of their starting point<br/>
                            - open and rich learning environments with clear parameters and more fluid rules<br/>
                            - interactive lectures, discussions, and games<br/>
                            - the ability to work independently, but alongside other interesting and highly engaged people<br/>
                            <br/>
                            - teachers who are open to unconventional ideas, approaches, solutions and who encourage CR thinking<br/>
                            - teachers and mentors who hold them accountable<br/>
                            - connecting with people who recognize/celebrate their insight, independence, creativity, inventiveness<br/>
                            - discussing moral and ethical issues, considerations
                        </p>
                    </div>
                    <div className={`${styles.column} ${styles.isHalf} ${styles.backgroundApple}`}>
                        <h3>Concrete Relationals worry about:</h3>
                        <hr className={styles.partialHr}/>
                        <p>
                            - being or being thought of as average, unremarkable<br/>
                            - trying to do everything (esp. after overcommitting)<br/>
                            - being forced to sacrifice one thing for another<br/>
                            - getting trapped in boring and restrictive situations<br/>
                            - being able to experience and achieve everything they set their minds to<br/>
                            - being dependent on people who lack vision or who they believe are incompetent<br/>
                            - dealing with overbearing or controlling managers<br/>
                            - be associated with people who lack moral/ethical fiber
                        </p>
                    </div>
                    <div className={`${styles.column} ${styles.isHalf} ${styles.backgroundBlueberry}`}>
                        <h3>Concrete Relationals develop when:</h3>
                        <hr className={styles.partialHr}/>
                        <p>
                            - they learn to balance competing requirements and priorities<br/>
                            - they temper their impulses and their tendency to be consumed by a single interesting project, task, etc.<br/>
                            - they develop patience with others, accept that others may not have immediately grasp their vision/process<br/>
                            - they learn to cooperate with other learners and see the value in their way of learning/processing information<br/>
                            - they develop their intuition and their capacity for being intellectually, emotionally, and socially insightful
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

ConcreteRelationalPrimaryPartialView.propTypes = {
    score: PropTypes.number
};

export default ConcreteRelationalPrimaryPartialView;