/**
 * Created by Robert on 6/14/19.
 */
import React, {Component} from 'react';
import * as styles from '../../../../styles/assessment.scss';
import PropTypes from 'prop-types';

class AbstractSequentialSecondaryPartialView extends Component {

    render() {
        return (
            <div>
                <div>
                    <h4 className={styles.learningOutcome}>Abstract Sequential (AS): {this.props.score}</h4>
                    <p className={styles.fontSmall1350}>
                        A secondary Abstract Sequential score
                        indicates that you have the capacity to engage with theoretical, conceptual, or
                        idea-based learning content and the ability to order this content in a clear, systematic,
                        and logical way.  In fact, in this mode your mental commitment to sequential order is so
                        strong that where a sequential structure is not obvious, you’ll even go out of your way
                        to create or uncover one.
                        <br/><br/>
                        In your Abstract Sequential capacity you have the ability to see both the forest AND the
                        trees.  In other words, you understand the bigger picture, but you can then break it down
                        into component parts and place them in a logical sequence.  In this mode you can make
                        crisp, clear categorical distinctions as a means of breaking learning content down into
                        digestible, understandable packets that can be ordered and easily remembered.  This
                        allows you to understand both the “why” and the “how” of a topic, and to compare and
                        contrast, and analyze and evaluate learning content with relative ease.
                        <br/><br/>
                        Your secondary learning style can be especially important to you when your environment or
                        circumstances ask things of you that outstrip the strengths and abilities of your primary
                        learning style.  You are most likely to tap into this secondary learning style if you are
                        asked to understand things in a highly logical fashion, provide clear explanations of
                        what you’ve learned, or when you are pursuing “why” questions that require highly
                        systematic thought.  In those situations, your secondary Abstract Sequential learning
                        style serves as a back-up mode that allows you to recognize cause and effect
                        relationships and patterns, identify major ideas and themes as well as nuances and
                        implications, and find the words and language you need to communicate your ideas.  Not
                        too shabby!
                    </p>
                </div>
            </div>
        );
    }
}

AbstractSequentialSecondaryPartialView.propTypes = {
    score: PropTypes.number
};

export default AbstractSequentialSecondaryPartialView;