import React, { Component } from 'react';
import LazyImage from '../components/lazyimage.js';
import crtPreloadBg from '../images/college-readiness-test/crt-bg-blur.jpg';
import crtFullBg from '../images/college-readiness-test/crt-bg.jpg';
import learningPreloadBg from '../images/learning-assessment/learning-bg-blur.jpg';
import learningFullBg from '../images/learning-assessment/learning-bg.jpg';
import learningConfig from '../config/assessment/learning/questions';
import valuesPreloadBg from '../images/values-assessment/values-bg-blur.jpg';
import valuesFullBg from '../images/values-assessment/values-bg.jpg';
import valuesConfig from '../config/assessment/values/questions';
import crtConfig from '../config/assessment/crt/questions';
import * as styles from '../styles/assessment.scss';
import { ASSESSMENT_TYPES } from '../../common/constants';
import LearningAssessmentQuestionsView from './assessments/learning-assessment/learning-assessment-questions.view';
import LearningAssessmentSubmitView from './assessments/learning-assessment/learning-assessment-submit.view';
import ValuesAssessmentQuestionsView from './assessments/values-assessment/values-assessment-questions.view';
import ValuesAssessmentSubmitView from './assessments/values-assessment/values-assessment-submit.view';
import CollegeReadinessTestQuestionsView from './assessments/college-readiness-test/college-readiness-test-questions.view';
import CollegeReadinessTestSubmitView from './assessments/college-readiness-test/college-readiness-test-submit.view';
import { isFlagEnabled, FEATURE_FLAGS } from '../../common/flags';
import PropTypes from 'prop-types';
import NavView from './nav.view';

class AssessmentView extends Component {
    constructor(props) {
        super(props);
        this.autofillAnswers = isFlagEnabled(FEATURE_FLAGS.AUTOFILL_ANSWERS, props.location.search);

        const assessmentType = this.props.assessmentType;
        if (assessmentType === ASSESSMENT_TYPES.LEARNING_STYLE) {
            this.backgroundStyle = styles.backgroundLearning;
            this.preloadBg = learningPreloadBg;
            this.fullBg = learningFullBg;
            this.config = learningConfig;
            this.heading = 'learning style assessment';
        } else if (assessmentType === ASSESSMENT_TYPES.VALUES) {
            this.backgroundStyle = styles.backgroundValues;
            this.preloadBg = valuesPreloadBg;
            this.fullBg = valuesFullBg;
            this.config = valuesConfig;
            this.heading = 'values assessment';
        } else if (assessmentType === ASSESSMENT_TYPES.CRT) {
            this.backgroundStyle = styles.backgroundCrt;
            this.preloadBg = crtPreloadBg;
            this.fullBg = crtFullBg;
            this.config = crtConfig;
            this.heading = 'college readiness test';
        }
    }

    componentDidUpdate() {
        if (this.autofillAnswers) {
            for (const answersContainer of [
                ...document.getElementsByClassName(styles.answersContainer),
                ...document.getElementsByClassName(styles.crtAnswersContainer)
            ]) {
                const random = Math.floor(Math.random() * 3);
                const randomRadio = answersContainer.querySelectorAll('input')[random];
                randomRadio.click();
            }

            const inputs = document.querySelectorAll('input[data-part=\'1\'][data-level=\'4\']');
            for (let i = 0; i < inputs.length; i += 5) {
                inputs[i + Math.floor(Math.random() * 3)].click();
            }
        }

        const mainDescription = document.getElementById('main-description');
        let descText = this.config.main.heading.description;
        descText = descText.replace(/\n/g, '<br>');
        mainDescription.innerHTML = descText;
    }

    render() {
        return (
            <div>
                <NavView/>
                <section id="header-section" className={`${styles.section} ${styles.vCentered} ${styles.halfHeight}`}>
                    <LazyImage
                        className={styles.backgroundContainer}
                        bg={true}
                        srcPreload={this.preloadBg}
                        srcLoaded={this.fullBg}
                        id={this.props.assessmentType + '-section'}
                    />
                    <div className={`${styles.column} ${styles.isFull} ${styles.lazyBackgroundContent}`}>
                        <div className={`${styles.bigHeader} animated fadeInDownSlow`}>
                            {this.heading}
                        </div>
                    </div>
                </section>
                <section
                    className={`${styles.section} ${styles.vCentered} ${styles.halfHeight} ${this.backgroundStyle}`}>
                    <div
                        className={`${styles.columnCount} ${styles.isMultiline} ${styles.widePaddingCondensedComputer}`}>
                        <div className={`${styles.column} ${styles.isFull}`}>
                            <h2>{this.config.main.heading.title}</h2>
                            <p id={'main-description'}></p>
                        </div>
                    </div>
                </section>
                {(this.props.assessmentType === ASSESSMENT_TYPES.LEARNING_STYLE && (
                    <React.Fragment>
                        <LearningAssessmentQuestionsView/>
                        <LearningAssessmentSubmitView/>
                    </React.Fragment>))}
                {(this.props.assessmentType === ASSESSMENT_TYPES.VALUES && (
                    <React.Fragment>
                        <ValuesAssessmentQuestionsView/>
                        <ValuesAssessmentSubmitView/>
                    </React.Fragment>))}
                {(this.props.assessmentType === ASSESSMENT_TYPES.CRT && (
                    <React.Fragment>
                        <CollegeReadinessTestQuestionsView/>
                        <CollegeReadinessTestSubmitView/>
                    </React.Fragment>
                ))}
            </div>
        );
    }
}

AssessmentView.propTypes = {
    assessmentType: PropTypes.string,
    location: PropTypes.object
};

export default AssessmentView;