/**
 * Created by Robert on 6/15/19.
 */
import React, {Component} from 'react';
import '../App.css';
import 'bulma/css/bulma.css';
import '../styles/main.scss';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import NavView from './nav.view';

class Header extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{display: 'none'}}/>
        );
    }

    componentDidMount() {
        library.add(fas, faTwitter);
        dom.watch();
    }
}

export default Header;

