/**
 * Created by Robert on 6/25/19.
 */
import React, { Component } from 'react';
import { Bar } from '@nivo/bar';
import { LegendSymbol, LegendProps } from '@nivo/legends';
import PropTypes from 'prop-types';

class CollegeReadinessTestBarView extends Component {
    constructor(props) {
        super(props);
        const {resultsConfig, questionsConfig, categories, family} = this.props;
        this.data = [];
        for (const [category, skill] of Object.entries(categories)) {
            this.data.push(
                {
                    'Category': category,
                    'Skill': skill
                }
            );
        }
        this.readableFamilyName = resultsConfig['readable family names'][family];
    }

    render() {
        const {resultsConfig, family} = this.props;
        const leftMargin = family == 'academic' ? 50 : 0;
        const rightMargin = family == 'pre-professional' ? 90 : 0;
        const width = (family == 'academic' ? 580 : family == 'personal' ? 430 : 330);
        return (

            <div className={'results-nivo-container'} key={family}>
                <h4 style={{textAlign: 'center', marginLeft: leftMargin, marginRight: rightMargin}}>{this.readableFamilyName}</h4>
                <Bar
                    {...this.props}
                    groupMode="grouped"
                    width={width}
                    height={600}
                    margin={{left: leftMargin, top: 10, bottom: 250, right: rightMargin}}
                    data={this.data}
                    indexBy="Category"
                    maxValue={5}
                    keys={['Skill']}
                    colors={resultsConfig['scoring']['aggregate']['legend']['colors'][family]}
                    borderWidth={2}
                    borderColor={{from: 'color'}}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    isInteractive={true}
                    // gridYValues={6}
                    axisLeft={{
                        tickValues: 6
                    }}
                    axisBottom={{
                        tickRotation: -90,
                    }}
                    theme={{
                        axis: {
                            fontSize: '16px',
                            ticks: {
                                text: {
                                    fontSize: 16
                                }
                            },
                            legend: {
                                text: {
                                    fontSize: 16
                                }
                            },
                        }
                    }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            symbolShape: ({
                                              x, y, size, fill, borderWidth, borderColor
                                          }) => (
                                <React.Fragment>
                                    <defs>
                                        <linearGradient id="grad1" x1="0%" y1="50%" x2="100%" y2="50%">
                                            <stop offset="0%" style={{stopColor: '#baffc9', stopOpacity:'1'}}/>
                                            <stop offset="33%" style={{stopColor: '#baffc9', stopOpacity:'1'}}/>
                                            <stop offset="34%" style={{stopColor: '#bae1ff', stopOpacity:'1'}}/>
                                            <stop offset="66%" style={{stopColor: '#bae1ff', stopOpacity:'1'}}/>
                                            <stop offset="67%" style={{stopColor: '#ffb3ba', stopOpacity:'1'}}/>
                                            <stop offset="100%" style={{stopColor: '#ffb3ba', stopOpacity:'1'}}/>
                                        </linearGradient>
                                    </defs>
                                    <rect
                                        x={x}
                                        y={y}
                                        fill={'url(#grad1)'}
                                        strokeWidth={borderWidth}
                                        stroke={borderColor}
                                        width={size}
                                        height={size}
                                        style={{pointerEvents: 'none'}}
                                    />
                                </React.Fragment>
                            ),
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                />
            </div>
        );
    }
}

CollegeReadinessTestBarView.propTypes = {
    resultsConfig: PropTypes.object,
    questionsConfig: PropTypes.object,
    categories: PropTypes.object,
    family: PropTypes.string
};

export default CollegeReadinessTestBarView;