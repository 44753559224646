/**
 * Created by Robert on 6/25/19.
 */
import React, { Component } from 'react';
import '../../../styles/animate.css';
import preloadBg from '../../../images/values-assessment/submit-bg-blur.jpg';
import fullBg from '../../../images/values-assessment/submit-bg.jpg';
import config from '../../../config/assessment/values/questions';
import 'whatwg-fetch';
import 'babel-polyfill';
import { FormHandler } from '../../../components/form-handler';
import AssessmentSubmissionForm from '../../assessment-submission-form.view';
import InputValidationUtil from '../../../../common/utility/input-validation.util';
import * as styles from '../../../styles/assessment.scss';

class ValuesAssessmentSubmitView extends Component {
    render() {
        return (
            <AssessmentSubmissionForm
                preloadBg={preloadBg}
                fullBg={fullBg}
                submitAnswersOverwrite={submitAnswersOverwrite}
                submitAnswersNoOverwrite={submitAnswersNoOverwrite}>
            </AssessmentSubmissionForm>
        );

        function submitAnswers(overwrite) {
            FormHandler.closeModal();
            let jsonData = {
                'type': 'values',
                'raw question data': {},
                'assessment': {
                    'answers': {
                        'categories': {}
                    }
                },
                'user': {
                    'first': document.getElementById('first-name-field').value,
                    'last': document.getElementById('last-name-field').value,
                    'email': document.getElementById('email-field').value,
                    'type': document.getElementById('type-field').value,
                    'do overwrite': overwrite
                }
            };

            for (let k = 0; k < [...Object.keys(config.categories)].length * config.questionsPerCategory; k++) {
                const elementIdentifier = 'question-' + (k + 1);
                const element = document.getElementById(elementIdentifier);
                const category = element.dataset.category;
                const answerLevel = FormHandler.getAnswerLevelFromInputs(document.getElementsByName(elementIdentifier));
                jsonData['raw question data'][elementIdentifier] = answerLevel;
                if (!(category in jsonData.assessment.answers.categories)) {
                    jsonData.assessment.answers.categories[category] = answerLevel;
                } else {
                    jsonData.assessment.answers.categories[category] = jsonData.assessment.answers.categories[category] + answerLevel;
                }
            }

            if (!FormHandler.validateAnswers(jsonData, InputValidationUtil.validateAnswersValues)) {
                return;
            }

            document.getElementById('assessment-form').classList.add(styles.sendingFilter);
            document.getElementById('spinner-wrapper').classList.remove(styles.fadeOut);

            FormHandler.postResults(jsonData);
        }

        function submitAnswersOverwrite() {
            submitAnswers(true);
        }

        function submitAnswersNoOverwrite() {
            submitAnswers(false);
        }
    }
}

export default ValuesAssessmentSubmitView;