/**
 * Created by Robert on 6/23/19.
 */
/**
 * Created by Robert on 6/14/19.
 */
import React, { Component } from 'react';
import * as styles from '../../../styles/assessment.scss';
import config from '../../../config/assessment/values/questions.json';

class ValuesAssessmentQuestionsView extends Component {
    constructor(props) {
        super(props);

        this.questionsContainer = [];
        this.questions = renderQuestions();
    }

    render() {
        if (this.questionsContainer.length === 0) {
            this.questionsContainer.push(this.questions);
        }

        return (
            <div>
                <div id="questions-container">
                    {this.questionsContainer}
                </div>
            </div>
        );
    }

    componentDidMount() {
        const radioButtons = document.getElementsByClassName(styles.formRadio);
        const onRadioButtonClick = (event) => {
            const radioButton = event.target;
            const container = document.getElementById(
                `answers-container-question-${radioButton.dataset.question}`);
            container.innerHTML = '<h4><i>' + config['answer levels'][radioButton.dataset.level] + '</i></h4>';
        };

        for (let i = 0; i < radioButtons.length; i++) {
            const radioButton = radioButtons[i];
            radioButton.addEventListener('click', onRadioButtonClick);
        }
    }
}

/* Randomly renders the questions onto the page. First selects a random category and then selects a random question
* from that category.
*/
function renderQuestions() {
    const elementArray = [];
    // We need to clone the object otherwise it will be deleted from the config. The following is a quick way to do so.
    let categories = JSON.parse(JSON.stringify(config.categories));
    let i = 0;
    while ([...Object.keys(categories)].length > 0) {
        let keys = [...Object.keys(categories)];
        let keyIndex = Math.floor(Math.random() * keys.length);
        let key = keys[keyIndex];
        let questionSet = categories[key].questions;
        let questionIndex = Math.floor(Math.random() * questionSet.length);
        let question = questionSet[questionIndex];
        const colorClass = (i % 2 == 0 ? styles.backgroundValuesDarker : styles.backgroundValues);

        elementArray.push(
            <section key={'vq-' + (i + 1)} id={'question-' + (i + 1)}
                     className={`${styles.section} ${styles.widePaddingMedium} ${styles.widePaddingCondensedComputer} ${styles.paragraphQuestionSection} ${colorClass}`}
            data-category={key}>
                <div className={styles.columnCount}>
                    <div className={`${styles.column} ${styles.is10}`}>
                        <div className={`${styles.columnCount} ${styles.isMultiline} ${styles.smallPaddingMedium}`}>
                            <div className={`${styles.column} ${styles.isFull}`}>
                                <h4>{question}</h4>
                            </div>
                        </div>
                        <div
                            className={`${styles.columnCount} ${styles.isMultiline} ${styles.questionsMultilineHelper} ${styles.isHiddenTouch}`}>
                            <div className={`${styles.column} ${styles.is2}`}>
                            </div>
                            <div className={`${styles.column} ${styles.is3} ${styles.vCentered}`}>
                                <p>
                                    <i>{config['answer levels'][0]}</i>
                                </p>
                            </div>
                            <div className={`${styles.column} ${styles.is2}`}>
                            </div>
                            <div className={`${styles.column} ${styles.is3} ${styles.vCentered}`}>
                                <p>
                                    <i>{config['answer levels'][4]}</i>
                                </p>
                            </div>
                            <div className={`${styles.column} ${styles.is2}`}>
                            </div>
                        </div>
                        <div className={`${styles.columnCount} ${styles.isMultiline}`}>
                            <div className={`${styles.column} ${styles.isFull} ${styles.answersContainer} ${styles.vCentered}`}>
                                {getAnswerOptions(i + 1)}
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.column} ${styles.is2} ${styles.answersWrapper}`}>
                        <div id={'answers-container-question-' + (i + 1)}
                             data-group={'questions'}>
                        </div>
                    </div>
                </div>
            </section>);

        questionSet.splice(questionIndex, 1);
        if (questionSet.length === 0) {
            delete categories[key];
        }
        i++;
    }

    return elementArray;
}

function getAnswerOptions(question) {
    const inputs = [];

    for (let i = 0; i < 5; i++) {
        const identifier = 'question-' + question;
        const numberClass = styles['formRadio' + (i + 1)];
        inputs.push(<input
            className={`${styles.formRadio} ${numberClass}`}
            key={'va-' + question + '-' + i}
            type="radio"
            name={identifier}
            data-level={i}
            data-question={question}/>);
    }

    return inputs;
}

export default ValuesAssessmentQuestionsView;