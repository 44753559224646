import React, { Component } from 'react';
import * as styles from '../../../../styles/assessment.scss';

class CrtPreparedPartialView extends Component {

    render() {
        return (
            <React.Fragment>
                <h4 className={styles.crtPrepared}>100-125 Prepared</h4>
                <p>
                    An aggregate score between 100 and 125 indicates that you have built a foundation of academic, personal, and
                    pre-professional skills appropriate to an entering college student. Said differently, and notwithstanding
                    weaker areas, you have the basis of required skills needed to be successful in your college or university.
                    BUT, in order to be successful throughout your entire college career, your skills cannot remain static.
                    Your college or university will present new academic, personal, and pre-professional challenges, and you
                    will need to adapt and advance your skills to rise to these occasions. In some cases, this may mean
                    adjusting your perspective on your education and your career objectives. Learn how our Master Courses and
                    Success Coaching options can help you reach your goals <a href="https://www.highpoint.education/collections">HERE</a>.
                </p>
            </React.Fragment>
        );
    }
}

export default CrtPreparedPartialView;