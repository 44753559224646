import React, {Component} from 'react';
import * as styles from '../../../../../styles/assessment.scss';
import config from '../../../../../config/assessment/values/questions.json';
import PropTypes from 'prop-types';

class ValuesDefinitionChart extends Component {
    constructor(props) {
        super(props);
        this.leftConflict = config.categories[props.leftConflict].name;
        this.rightConflict = config.categories[props.rightConflict].name;
        this.leftScore = props.leftScore;
        this.rightScore = props.rightScore;
    }

    render() {
        return (
            <div className={`${styles.column} ${styles.isFull}`}>
                <div className={`${styles.columnCount} ${styles.isMultiline} ${styles.valuesContainer}`}>
                    <div className={`${styles.column} ${styles.isHalf} ${styles.conflictChart} ${styles.conflictChartLeft}`}>
                        <h3 className={styles.valuesScore}>{this.leftScore}</h3>
                        <h2>{this.leftConflict}</h2>
                    </div>
                    <div className={`${styles.column} ${styles.isHalf} ${styles.conflictChart} ${styles.conflictChartRight}`}>
                        <h2>{this.rightConflict}</h2>
                        <h3 className={styles.valuesScore}>{this.rightScore}</h3>
                    </div>
                </div>
            </div>
        );
    }
}

ValuesDefinitionChart.propTypes = {
    leftConflict: PropTypes.string,
    leftScore: PropTypes.number,
    rightConflict: PropTypes.string,
    rightScore: PropTypes.number,
};

export default ValuesDefinitionChart;