import React, { Component } from 'react';
import * as styles from '../../../styles/assessment.scss';

class ValuesResultsContentPartialView extends Component {

    render() {
        return (
            <section className={`${styles.section} ${styles.fullHeight} ${styles.primaryBackground} ${styles.pageMargin}`}>
                <div className={`${styles.columnCount} ${styles.isMultiline} ${styles.widePadding}`} style={{paddingTop: 0}}>
                    <div className={`${styles.column} ${styles.isFull}`}>
                        <h2 style={{textAlign: 'center'}}>
                            What Are Values and What Do Your Scores Mean?
                        </h2>
                        <p className={styles.fontSmall1350}>
                            As human beings, we possess abstract understandings and deeply rooted beliefs of what is
                            important. We call these things “values” and we name them with reference to their specific
                            domains. In more technical terms, values are cognitive structures that are closely connected with
                            affect, emotion, decision-making, and actions. You can think of them like deeply seated beliefs
                            that orient you to desirable goals or objects.
                            <br/><br/>
                            Values serve as standards or criteria in our everyday world of experience. We use values to
                            determine worth. We use them to evaluate people, actions, objects, and experiences. We even use
                            values to justify, guide, or explain our view of the world, our opinions and norms, and
                            (especially) our actions and reactions.
                            <br/><br/>
                            While certain values exist for all people as such, values are not static features of our psyche.
                            They exist relative to each other and in relation to different external factors, from cultures
                            right down to the level of individual experience. As a result, individuals will form their own
                            hierarchies of values, and these will differ from one person to the next and even from one culture
                            to the next. And importantly, your hierarchy of values may shift over time as new experiences
                            shift your perspective on where you are and what matters to you most. This assessment is designed
                            to gauge your hierarchy of values and to identify your dominant values—you can think of your score
                            as a snapshot of what matters most to you right now.
                        </p>
                        <h2 style={{textAlign: 'center'}}>
                            How to Read Your Scores
                        </h2>
                        <div className={styles.fontSmall1350}>
                            <p>
                                Below you’ll see your results broken down to show your aggregate score, your individual value scores,
                                your dominant values, and critical value conflicts (if any). Preceding each display, you will find an
                                explanation of how to interpret that scoring aspect. Each of the displays can tell you more about your
                                hierarchy of values and how it impacts your day-to day life, so read each one closely and return to
                                different sections as you reflect on what your scores reveal.
                                <br/><br/>
                                <b>Aggregate Score:</b> Your aggregate score is the sum of all of your individual value scores; this score
                                can give you an indication of how strongly you feel your values and how much you allow them to play
                                a role in your affect, emotion, decision-making, and actions. This score can be complicated to
                                read and may require that you look closely at your individual value scores. A high ranging score
                                (above 180) might indicate that you feel your values very strongly, or it might also indicate that
                                you do not distinguish your values from one another very clearly. A low ranging score (below 135)
                                might indicate that you are somewhat apathetic toward your values in general, or it could show that
                                you one or a few values are especially strong in comparison to the others. Read your aggregate
                                score carefully and in connection to your individual value scores and your dominant values.
                            </p>
                            <div className={`${styles.column} ${styles.isFull} ${styles.printPageBreakAfter}`}>
                                <h3 className={styles.vCentered}>
                                    <span id="aggregate-score" className={styles.valuesScore}>{this.score}</span>&nbsp;Aggregate Score
                                </h3>
                                <br/>
                                <p>
                                <b>Individual Value Scores (chart):</b> Your individual value scores show how you rank each value
                                construct relative to the others; this set of scores displays the entire range of your value
                                hierarchy and can help you better understand the landscape of your values.
                                </p>
                                <br/>
                                <h3 style={{textAlign: 'center'}}>Individual Values Scores</h3>
                                <div id="aggregate-column" className={`${styles.vCentered} ${styles.halveOnPrint}`}>
                                </div>
                            </div>
                            <p>
                                <b>Dominant Values:</b> Your dominant vales are your five highest scoring values relative to the entire
                                range of values assessed. Because your dominant values play the greatest role in your
                                day-to-day life, we separate them out so that you can consider them individually and in greater
                                detail. For each of your dominant values we include a description of the value construct as well
                                as a chart showing supporting, associated, and competing/conflict values. Supporting values
                                encourage or affirm your dominant value; associated values are related to your dominant value in
                                such a way that they can influence the extent or manifestation of it; competing/conflicting values
                                are those which are fundamentally incongruent with your dominant value.
                            </p>
                            <br/>
                            <h3 style={{textAlign: 'center'}}>Dominant Values</h3>
                            <div className={`${styles.column} ${styles.isFull}`}>
                                <div id="dominant-value-0" className={styles.scoreResultsDisplay}>
                                </div>
                                <div id="dominant-value-1" className={styles.scoreResultsDisplay}>
                                </div>
                                <div id="dominant-value-2" className={styles.scoreResultsDisplay}>
                                </div>
                                <div id="dominant-value-3" className={styles.scoreResultsDisplay}>
                                </div>
                                <div id="dominant-value-4" className={styles.scoreResultsDisplay}>
                                </div>
                            </div>
                            <p>
                                <b>Critical Value Conflicts:</b> If any of your dominant values are fundamentally incongruent with one
                                another, you will see these displayed in this section. When dominant values are fundamentally incongruent
                                with one another, this can put you in tension with yourself, even without your explicit or conscious awareness
                                of this dissonance. It is very important to recognize and understand these value conflicts since they can
                                draw you in different directions in your day-to-day life, impede your ability identify and reach your goals,
                                and result in ambient or even acute anxiety among other forms of mental and emotional distress.
                            </p>
                            <br/>
                            <h3 style={{textAlign: 'center'}}>Critical Value Conflicts</h3>
                            <div className={`${styles.column} ${styles.isFull}`}>
                                <div
                                    id="conflicts-column"
                                    className={`${styles.vCentered} ${styles.halveOnPrint} ${styles.columnCount} ${styles.isMultiline}`}>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.column} ${styles.isFull}`}>
                        <h2 style={{textAlign: 'center'}}>
                            What do Your Scores Mean?
                        </h2>
                        <p className={styles.fontSmall1350}>
                            It is healthy for everyone to have a strong grasp of what matters to them, why, and how it impacts
                            their daily life. Unfortunately, many people take their values for granted or are somewhat unaware
                            of their core values. This assessment is designed to indicate your values and provide you with
                            material for self-reflection. The farther along you get in the process of self-reflection, though,
                            the more you may find this assessment practically useful as well. Understanding your hierarchy of
                            values will not only deepen your self-understanding, it can also help you discover where your
                            motivations lie and how to tap into them, determine which career paths you might find most
                            rewarding, and it can show you which activities can give you the greatest sense of fulfillment. In
                            short, this assessment is a tool for learning how you can live a meaningful and happy life.
                            <br/><br/>
                            Now, the experience of taking the values assessment can be validating, encouraging, and even
                            inspiring. But, it can also be surprising and unsettling if you discover things about yourself
                            that you weren’t aware of beforehand. Self-reflection takes practice, and learning how to act on
                            what you’ve learned about yourself requires confidence, careful planning, and resolution. Try not
                            to feel overwhelmed by your assessment results, and remember that we’re here to help you make
                            progress toward your goals.
                            <br/><br/>
                            Our Master Classes, the Empowered Student and the Apex Student, both include a series of units
                            designed to help you build your introspective skills and turn them into self-efficacy. Learn more
                            about our Master Classes <a href={'https://www.highpoint.education/collections'}>HERE</a>.
                            <br/><br/>
                            Our highly trained Success Coaches can help you consider your strengths, establish new goals, and
                            rise to new challenges. Learn more about Success Coaching <a href={'https://www.highpoint.education/pages/coaching'}>HERE</a>.
                        </p>
                    </div>
                </div>
            </section>
        );
    }
}

export default ValuesResultsContentPartialView;