/**
 * Created by Robert on 6/25/19.
 */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ValuesAssessmentResultsAggregate from './components/values-assessment-results-aggregate.js';
import * as styles from '../../../../styles/assessment.scss';
import axios from 'axios';
import config from '../../../../config/assessment/values/results.json';
import questionsConfig from '../../../../config/assessment/values/questions.json';
import ValuesResultsContentPartialView from '../../../../config/assessment/values/values-results-content.partial.view';
import ValuesDefinitionChart from './components/values-definition-chart';
import CriticalConflictChart from './components/critical-conflict-chart';
import backgroundImages from '../../../../images/values-assessment/values/*.jpg';

class ValuesAssessmentResultsView extends Component {
    render() {
        let token = getUrlVars()['token'];

        if (token == null) {
            this.props.history.push(`/404`);
            return null;
        }

        return (
            <section id='fade-section' className={`${styles.fadeWrapper} ${styles.fadeOut}`}>
                <section id='results-section' className={`${styles.section} ${styles.vCentered} ${styles.valuesBackground} ${styles.whiteText} ${styles.pageMargin}`}>
                    <div className={`${styles.columnCount} ${styles.isMultiline}`}>
                        <div className={`${styles.column} ${styles.isFull} ${styles.vCentered}`} id='results-header'>
                        </div>
                    </div>
                </section>
                <ValuesResultsContentPartialView/>
            </section>
        );
    }

    async componentDidMount() {
        let responseDocument = await getResponseDocument();
        if (responseDocument == null) {
            this.props.history.push(`/404`);
            return null;
        }

        this.headerView = (<h1>Your results are in, {responseDocument.user[0].firstName}!</h1>);

        if (window.innerWidth < 1350) {
            this.headerView = (
                <h2 style={{textAlign: 'center'}}>Your results are<br/>in, {responseDocument.user[0].firstName}!</h2>);
        }

        ReactDOM.render(this.headerView,
            document.getElementById('results-header'));

        this.aggregateView = (<ValuesAssessmentResultsAggregate
            categories={responseDocument.assessment[0].answers[0].categories}
            config={config}
        />);
        ReactDOM.render(this.aggregateView, document.getElementById('aggregate-column'));

        document.getElementById('aggregate-score').innerHTML = responseDocument.assessment[0].data.aggregate;

        addDominantValues(responseDocument.assessment[0]);

        document.getElementById('fade-section').classList.remove(styles.fadeOut);

        window.addEventListener('resize', () => resizeItems());
        resizeItems();
    }
}

async function getResponseDocument() {
    return axios.post('/api/assessment-results/get-answers/values', {token: getUrlVars()['token']})
        .then((response) => {
            let data = response.data;
            if (data == null || data.document == null || data.status !== 'good') {
                return null;
            }

            return data.document;
        })
        .catch((error) => {
            console.error(error);
            return null;
        });
}

function addDominantValues(assessment) {
    let i = 0;
    const dominantValues = assessment.data.dominant;
    const conflictMap = [];
    const conflictContainer = [];
    let key = 0;

    // Check the dominant values given from the document
    for (const dominantValue of dominantValues) {
        const definition = getDefinition(dominantValue);
        const supporting = getSupportingValues(dominantValue);
        const associated = getAssociatedValues(dominantValue);
        const conflicting = getConflictingValues(dominantValue);
        const backgroundImage = getBackgroundImageForValue(dominantValue);

        const dominantContainer = document.getElementById('dominant-value-' + i++);

        ReactDOM.render(<ValuesDefinitionChart
            value={dominantValue}
            definition={definition}
            supporting={supporting}
            associated={associated}
            conflicting={conflicting}
            score={assessment.answers[0].categories[dominantValue]}
            backgroundImage={backgroundImage}
        />, dominantContainer);

        // Now check through the conflicts for this dominant value
        for (const conflictingValue of conflicting) {
            // Check to see if the conflicting value is a dominant value
            if (dominantValues.includes(conflictingValue)) {
                // Check to see if the reverse has already been added to the map. Do not add since it is identical.
                if (conflictPairExists(conflictMap, [conflictingValue, dominantValue])) {
                    continue;
                }
                // Add conflict to the conflict array and add the element
                conflictMap.push([dominantValue, conflictingValue]);
                conflictContainer.push(<CriticalConflictChart
                    key={key++}
                    leftConflict={dominantValue}
                    leftScore={assessment.answers[0].categories[dominantValue]}
                    rightConflict={conflictingValue}
                    rightScore={assessment.answers[0].categories[conflictingValue]}
                />);
            }
        }
    }

    ReactDOM.render(conflictContainer, document.getElementById('conflicts-column'));
}

function conflictPairExists(conflictMap, pair) {
    for (let i = 0; i < conflictMap.length; i++) {
        let checker = false
        for (let j = 0; j < conflictMap[i].length; j++) {
            if (conflictMap[i][j] === pair[j]) {
                checker = true
            } else {
                checker = false
                break;
            }
        }
        if (checker) {
            return true
        }
    }
    return false
}

function getDefinition(value) {
    return questionsConfig.categories[value].definition;
}

function getSupportingValues(value) {
    return questionsConfig.categories[value].supporting;
}

function getAssociatedValues(value) {
    return questionsConfig.categories[value].associated;
}

function getConflictingValues(value) {
    return questionsConfig.categories[value].conflicting;
}

function getBackgroundImageForValue(value) {
    return backgroundImages[value];
}

function resizeItems() {
    let elements = document.getElementsByClassName('results-nivo-container');
    let windowWidth = window.innerWidth;
    let calculated = (windowWidth / 1335) + 0.1;

    if (calculated > 1) {
        calculated = 1;
    }

    for (let i = 0; i < elements.length; i++) {
        elements[i].style.transform = `scale(${calculated})`;
    }
}

function getUrlVars() {
    let vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
        (m, key, value) => {
            vars[key] = value;
        });
    return vars;
}

export default ValuesAssessmentResultsView;