class DataProcessorUtil {
    static calculateValuesData(valuesCategories) {
        let aggregate = 0;
        for (let [, value] of Object.entries(valuesCategories)) {
            aggregate += value;
        }

        let sorted = Object.keys(valuesCategories).sort((a, b) => valuesCategories[b] - valuesCategories[a]);
        let top5 = sorted.slice(0,5);
        return {
            aggregate: aggregate,
            dominant: top5
        };
    }

    static fullNameToIdentifier(fullName) {
        return fullName.toLowerCase().replace(/ /g, '-');
    }
}

export default DataProcessorUtil;